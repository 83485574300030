import { Dispatch, SetStateAction, createContext, useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// Custom Components
import RouteConfiguration from "RouteConfiguration";
import Alert, { showAlertGlobal } from 'components/v1/main/Alert';
import { Toast } from "primereact/toast";

// Custom functions
import apiHandler from 'functions/v1/main/apiHandler'
import { consoleLog } from "functions/v1/main/utilitiesFunctions";

// Custom types
import { AxiosError } from 'axios';
import { ApiHandlerProps } from 'types/v1/main/apiHandlerTypes';
import { FeatureAccess, UserInfo } from "types/v1/security/userAccessTypes";
import { ModuleConfigSettingsType } from "types/v1/module/configurationTypes";
import { AlertInformation } from "types/v1/main/alertTypes";

// Global State Context Providers
export const UserInfoContext = createContext<UserInfo | undefined>(undefined);
export const ModuleConfigSettingsContext = createContext<ModuleConfigSettingsType>({});
export const SetModuleConfigSettingsContext = createContext<Dispatch<SetStateAction<ModuleConfigSettingsType>> | undefined>(undefined);
export const ShowToastContext = createContext<(alert: AlertInformation) => void>(() => {});

// The API Handler context provider
export const APIHandlerContext = createContext<ApiHandlerProps>(apiHandler((error: AxiosError) => { }, ""));

// We define the routes for our application here in the App component
export default function GlobalContextProviders() {

  // Default error handler
  const navigate = useNavigate();
  const defaultErrorHandler = (error: AxiosError) => {
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      // Handle error on dev
      consoleLog(error);
      let errorData = {
        errorMessage: error.message,
        requestType: error.config?.method,
        requestURL: error.request?.responseURL,
        requestData: error.config?.data,
        responseData: error.response?.data
      }
      navigate("/development/detailed-error", { state: errorData });
    }
    else {
      // Handle errors when deployed
      if (error.response?.status === 401 || error.response?.status === 403) {
        navigate("/not-authorized");
        return;
      }
      else {
        showAlert({
          type: "error",
          summary: "Error",
          detail: "We've encountered an error. Please reload the page and try again.",
          life: 10000
        });
      }
    }
  }

  // Global State Variables
  const [userInfo, setUserInfo] = useState<UserInfo | undefined>(undefined);
  const [moduleConfigSettings, setModuleConfigSettings] = useState<ModuleConfigSettingsType>({});

  // Global Reference Variables
  const toast = useRef<Toast>(null);

  // Show alert function
  const showAlert = useCallback((alert: AlertInformation) => {
    showAlertGlobal(alert, toast);
  }, []);

  // Global API Handler
  const [b2gAPI, setB2gAPI] = useState<ApiHandlerProps>(apiHandler(defaultErrorHandler, ""));

  // Get the user access data
  useEffect(() => {
      b2gAPI.get(`/users/user`, function (response) {
        let user = response.data.response;

        b2gAPI.getTyped<FeatureAccess[]>(`/users/featureaccess`, function (response) {
          setUserInfo({
            firstName:user.firstName,
            lastName:user.lastName,
            companyId:user.companyID,
            vendorId:user.companyID,
            email:user.email,
            userTypeId: user.userType,
            userId: user.identifier,
            featureAccess: response
          });
        });
      },() => {});
  }, [b2gAPI]); // This is set to only run when the api closure variable updates

  return (
    <UserInfoContext.Provider value={userInfo}> {/* Provide the user info settings to all routes */}
      <SetModuleConfigSettingsContext.Provider value={setModuleConfigSettings}>
        <ModuleConfigSettingsContext.Provider value={moduleConfigSettings}>
          <APIHandlerContext.Provider value={b2gAPI}>
            <ShowToastContext.Provider value={showAlert}>
              <Alert innerRef={toast} />
              <RouteConfiguration 
                defaultErrorHandler={defaultErrorHandler} 
                b2gAPI={b2gAPI} 
                setB2gAPI={setB2gAPI}
              />
            </ShowToastContext.Provider>
          </APIHandlerContext.Provider>
        </ModuleConfigSettingsContext.Provider>
      </SetModuleConfigSettingsContext.Provider>
    </UserInfoContext.Provider>
  );
}
