import { InputTextarea } from 'primereact/inputtextarea';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Editor as PrimeReactEditor } from 'primereact/editor';
import { RadioButton as PrimeReactRadioButton } from 'primereact/radiobutton';
import { Checkbox as PrimeReactCheckbox } from 'primereact/checkbox';
import { Password } from 'primereact/password';
import { TimeProps, DateTimeProps, DateProps, SelectDropDownProps, MultiSelectDropDownProps, TextAreaProps, TextProps, EditorProps, ValidationErrorProps, CheckBoxProps, RadioButtonListProps, RadioButtonOption, FilterDropDownProps, SearchBarProps, FilterDatesProps, AddressAutocompleteProps, NumberProps, CurrencyProps, AutoCompleteProps, SwitchProps, PasswordProps } from 'types/v1/main/form/inputTypes';
import { Controller } from 'react-hook-form';
import { OverlayPanel } from 'primereact/overlaypanel';
import Button from './Button';
import { useEffect, useRef, useState } from 'react';
import { formatDateMMSlashDDSlashYY } from 'functions/v1/main/dateFunctions';
import { endOfDay, startOfDay } from 'date-fns';
import { usePlacesWidget } from 'react-google-autocomplete';
import { MultiSelect } from 'primereact/multiselect';
import { AutoComplete as InputAutoComplete } from 'primereact/autocomplete';
import { InputSwitch } from 'primereact/inputswitch';
        

const Input = (props : any) => {  
    return (
       <></> 
    )
  }
  
const Text = (props : TextProps) => {
  let placeholder = props.placeholder || "";

    return (
      <>
        {props.control ? (
          <Controller 
          name={props.name} 
          control={props.control} 
          rules={props.rules} 
          render={({ field, fieldState }) => (
            <>
              <InputText 
              value={field.value} 
              id={field.name} 
              ref={field.ref} 
              onChange={
                (e) => {
                  let target = e.target;
                  target.value = target.value.replaceAll('~', '');

                  // Update the controller value
                  field.onChange(target.value);

                  // Run our custom function
                  if (props.onChange !== undefined) {
                    props.onChange(e);
                  }
                }
              } 
              onClick={props.onClick}
              maxLength={props.maxLength}
              className={"width-100 " + props.className}
              placeholder={placeholder} 
              tooltip={props.tooltip} 
              tooltipOptions={props.tooltipOptions} 
              disabled={props.disabled}
              onKeyUp={props.onKeyUp} /> 

              {fieldState.invalid && // Error message
                <Input.ValidationError>{fieldState.error?.message}</Input.ValidationError>
              } 
            </>
          )}/>   
        ) : (
          <InputText 
            name={props.name} 
            value={props.value} 
            id={props.name} 
            ref={props.ref}
            onChange={props.onChange}
            onClick={props.onClick}
            className={"width-100 " + props.className}
            placeholder={placeholder} 
            tooltip={props.tooltip} 
            tooltipOptions={props.tooltipOptions} 
            disabled={props.disabled}
            maxLength={props.maxLength}
            onKeyUp={props.onKeyUp}
          />
        )}
      </>
    )
  }

const AddressAutocomplete = (props : AddressAutocompleteProps) => {

  const defaultOptions = {
    types: ['address'],
    componentRestrictions: { country: ["us", "ca"] }
  }

  const { ref } = usePlacesWidget<HTMLInputElement>({
    apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    onPlaceSelected: props.googleAutocompleteInputProps.onPlaceSelected,
    options: props.googleAutocompleteInputProps.options ?? defaultOptions
  });
  
  return (
    <input 
      onChange={props.onChange}
      name={props.name} 
      id={props.name} 
      ref={ref} 
      defaultValue={props.defaultValue} 
      className="p-inputtext p-component width-100" 
      disabled={props.disabled}
    />
  )
}

  const SearchBar = (props : SearchBarProps) => {
      let classes = "p-input-icon-left w-100";
      let className="w-100"
      if (props.className != null)
        classes += " " + props.className;
      if (props.phClassName != null)
        className += " " + props.phClassName;
      return (
        <span className={classes}>
          <i className="pi pi-search" />
          <InputText 
            name={props.name} 
            value={props.value} 
            onChange={props.onChange} 
            placeholder={props.placeholder}
            disabled={props.disabled}
            className={className}
          />
        </span>
      )
  }


const TextArea = (props : TextAreaProps) => {
let placeholder = props.placeholder || "";

  return (
    <>
      {props.control ? (
        <Controller 
        name={props.name} 
        control={props.control} 
        rules={props.rules} 
        render={({ field, fieldState }) => (
          <>
            <InputTextarea 
            value={field.value} 
            id={field.name} 
            ref={field.ref}
            onChange={
              (e) => {
                let target = e.target;
                target.value = target.value.replace('~', '');

                // Update the controller value
                field.onChange(target.value);

                // Run our custom function
                if (props.onChange !== undefined) {
                  props.onChange(e);
                }
              }
            } 
            rows={(props.rows !== null) ? props.rows : 5 } 
            className="width-100" 
            placeholder={placeholder}
            tooltip={props.tooltip} 
            tooltipOptions={props.tooltipOptions} 
            disabled={props.disabled} 
            style={props.style}
			readOnly={props.readonly}/> 
            
            {fieldState.invalid && // Error message
              <Input.ValidationError>{fieldState.error?.message}</Input.ValidationError>
            }
          </>
        )} /> 
      ) : (
        <InputTextarea 
          value={props.value} 
          id={props.name} 
          onChange={props.onChange} 
          rows={5} 
          className="width-100"
          placeholder={placeholder}
          tooltip={props.tooltip} 
          tooltipOptions={props.tooltipOptions} 
          disabled={props.disabled} 
          style={props.style} 
        />
      )}
    </>     
  )
}

const SelectDropDown = (props: SelectDropDownProps) => {
  let placeholder = props.placeholder || "Select an Option";

  return (
    <>
      {props.control ? (
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          render={({ field, fieldState }) => (
            <>
              <Dropdown 
              value={field.value}
              ref={field.ref}
              id={field.name}
              onChange={
                (e) => {
                  // Update the controller value
                  field.onChange(e.value);

                  // Run our custom function
                  if (props.onChange !== undefined) {
                    props.onChange(e);
                  }
                }
              }
              options={props.options}
              optionLabel={props.optionLabel}
              placeholder={placeholder} 
              focusInputRef={field.ref}  
              className={props.className ?? "w-full md:w-14rem"}
              tooltip={props.tooltip} 
              tooltipOptions={props.tooltipOptions}
              disabled={props.disabled}   
              showClear={props.showClear}  
              inputId={props.inputId}
              onHide={props.onHide}
			  dataKey={props.dataKey}
              />

              {fieldState.invalid && // Error message
                <Input.ValidationError>{fieldState.error?.message}</Input.ValidationError>
              }
            </>
          )}
        />
      ) : (
        <Dropdown 
          value={props.value}
          id={props.name}
          onChange={props.onChange}
          options={props.options}
          optionLabel={props.optionLabel}
          placeholder={placeholder}  
          className={props.className ?? "w-full md:w-14rem"}
          tooltip={props.tooltip} 
          tooltipOptions={props.tooltipOptions}
          disabled={props.disabled} 
          showClear={props.showClear}
          inputId={props.inputId}
          onHide={props.onHide}
        />
      )}
    </>
  ) 
}

const MultiSelectDropDown = (props: MultiSelectDropDownProps) => {
  let placeholder = props.placeholder || "Select an Option";

  return (
    <>
      {props.control ? (
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          render={({ field, fieldState }) => (
            <>
              <MultiSelect 
              value={field.value}
              inputRef={field.ref}
              id={field.name}
              onChange={
                (e) => {
                  // Update the controller value
                  field.onChange(e.value);

                  // Run our custom function
                  if (props.onChange !== undefined) {
                    props.onChange(e);
                  }
                }
              }
              options={props.options}
              optionLabel={props.optionLabel}
              placeholder={placeholder} 
              className={props.className ?? "w-full md:w-14rem"}
              tooltip={props.tooltip} 
              tooltipOptions={props.tooltipOptions}
              disabled={props.disabled}   
              showClear={props.showClear}  
              inputId={props.inputId}
              onHide={props.onHide}
              optionGroupTemplate={props.optionGroupTemplate}
              optionGroupLabel={props.optionGroupLabel}
              optionGroupChildren={props.optionGroupChildren}
              maxSelectedLabels={props.maxSelectedLabels}
              filter={props.filter}
              />

              {fieldState.invalid && // Error message
                <Input.ValidationError>{fieldState.error?.message}</Input.ValidationError>
              }
            </>
          )}
        />
      ) : (
        <MultiSelect 
          value={props.value}
          id={props.name}
          onChange={props.onChange}
          options={props.options}
          optionLabel={props.optionLabel}
          placeholder={placeholder}  
          className={props.className ?? "w-full md:w-14rem"}
          tooltip={props.tooltip} 
          tooltipOptions={props.tooltipOptions}
          disabled={props.disabled} 
          showClear={props.showClear}
          inputId={props.inputId}
          onHide={props.onHide}
          optionGroupTemplate={props.optionGroupTemplate}
          optionGroupLabel={props.optionGroupLabel}
          optionGroupChildren={props.optionGroupChildren}
          maxSelectedLabels={props.maxSelectedLabels}
          filter={props.filter}
        />
      )}
    </>
  ) 
}

const FilterDropDown = (props: FilterDropDownProps) => {
  let placeholder = props.placeholder || "Select an Option";

  return (
    <Dropdown
      id={props.id}
      value={props.value} 
      options={props.options} 
      optionLabel={props.optionLabel}
      onChange={props.onChange} 
      itemTemplate={props.itemTemplate} 
      placeholder={placeholder} 
      className={"p-column-filter " + props.className}
      showClear={props.showClear}
      style={props.style} 
      disabled={props.disabled}
    />
  ) 
}

const FilterDates = (props: FilterDatesProps) => {

  // Component specific reference variables
  const datePostedOverlayPanel = useRef<any>(null);
  
  // Component specific state variables
  const [datePostedButtonLabel, setDatePostedButtonLabel] = useState("No Filter");
  const [datePostedErrorMessageVisible, setDatePostedErrorMessageVisible] = useState(false);
  const [dateStart, setDateStart] = useState<Date | null | undefined>(null);
  const [dateEnd, setDateEnd] = useState<Date | null | undefined>(null);

  // Sideeffect of the external dates being updated
  useEffect(() => {
    // Rename the overlay panel label based on the new dates
    let datePostedLabelTemp = "";

    // Set the inner label when the outer dates are changed
    if (props.dateStart != null && props.dateEnd != null) {
      datePostedLabelTemp = formatDateMMSlashDDSlashYY(props.dateStart) + " - " + formatDateMMSlashDDSlashYY(props.dateEnd);
    }
    else if (props.dateStart != null) {
      datePostedLabelTemp = formatDateMMSlashDDSlashYY(props.dateStart) + " - ";
      setDatePostedErrorMessageVisible(false);
    }
    else if (props.dateEnd != null) {
      datePostedLabelTemp = " - " + formatDateMMSlashDDSlashYY(props.dateEnd);
      setDatePostedErrorMessageVisible(false);
    }
    else {
      datePostedLabelTemp = "Select a Filter";
      setDatePostedErrorMessageVisible(false);
    }

    setDatePostedButtonLabel(datePostedLabelTemp);
  
  }, [props.dateStart, props.dateEnd]);

  // Side effect of the internal dates being updated
  useEffect(() => {
    // Check if start date is before end date
    if (dateStart != null && dateEnd != null) {
      if (dateStart > dateEnd) {
        // Throw up a warning.
        setDatePostedErrorMessageVisible(true);
        return;
      } 
      else {
        setDatePostedErrorMessageVisible(false);
      }
    }
    
  }, [dateStart, dateEnd]);

  return (
    <>
      <Button label={datePostedButtonLabel} onClick={(e) =>  {
        // Set the inner values to the outer values
        setDatePostedErrorMessageVisible(false);
        setDateStart(props.dateStart != null ? startOfDay(props.dateStart) : props.dateStart);
        setDateEnd(props.dateEnd != null? endOfDay(props.dateEnd) : props.dateEnd);

        // Show the overlay panel
        datePostedOverlayPanel.current.toggle(e);
      }} />

      <OverlayPanel ref={datePostedOverlayPanel}>
        <Input.Date 
          name="dateStart"
          value={dateStart}
          placeholder={props.dateStartPlaceholder ?? "Start Date"} 
          onChange={(e) => {
            if (typeof e.value !== 'string' && !Array.isArray(e.value)) {
              setDateStart(e.value);
            }
          }}
        />
        <span> {props.datesSeparator ?? "to"} </span>
        <Input.Date 
          name="dateEnd"
          value={dateEnd} 
          placeholder={ props.dateEndPlaceholder ?? "End Date"}
          onChange={(e) => {
            if (typeof e.value !== 'string' && !Array.isArray(e.value)) {
              setDateEnd(e.value);
            }
          }}
        />

        {datePostedErrorMessageVisible &&
          <div className="col-12 text-center mt-2"><Input.ValidationError>Please make sure the start date is before the end date.</Input.ValidationError></div>
        }

        <div className="col-12 text-center mt-2">
          <Button label="Filter" onClick={(e) => {

            if (dateStart != null && dateEnd != null) {
              if (dateStart > dateEnd) { // Make sure the dates aren't mismatched
                return;
              }
            }

            // Set the outer values to cause the reload of the list page
            props.setDateStart(dateStart);
            props.setDateEnd(dateEnd);

            // Hide that overlay panel
            datePostedOverlayPanel.current.toggle(e);
          }} />

          <Button label="Clear" onClick={(e) => {
            setDateStart(null);
            setDateEnd(null);
            props.setDateStart(null);
            props.setDateEnd(null);

            // Hide that overlay panel
            datePostedOverlayPanel.current.toggle(e);
          }} />

        </div>

      </OverlayPanel>
    </>
  );
}

const Date = (props: DateProps) => {
  let placeholder = props.placeholder || "Select a Date";

  return (
    <>
      {props.control ? (
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          render={({ field, fieldState }) => (
            <>
              <Calendar 
              value={field.value} 
              ref={field.ref}
              onChange={
                (e) => {
                  // Update the controller value
                  field.onChange(e.value);

                  // Run our custom function
                  if (props.onChange !== undefined) {
                    props.onChange(e);
                  }
                }
              } 
              placeholder={placeholder}
              disabled={props.disabled}
              tooltip={props.tooltip}
              minDate={props.minDate}
              maxDate={props.maxDate}
              tooltipOptions={props.tooltipOptions} />   

              {fieldState.invalid && // Error message
                <Input.ValidationError>{fieldState.error?.message}</Input.ValidationError>
              }
            </>
          )}
        />
        ) : (
          <Calendar 
            value={props.value} 
            name={props.name}
            onChange={props.onChange} 
            placeholder={placeholder}
            tooltip={props.tooltip}
            tooltipOptions={props.tooltipOptions} 
            disabled={props.disabled}
            minDate={props.minDate}
            maxDate={props.maxDate}
			    />  
        )}
    </> 
  )
  
}

const DateTime = (props: DateTimeProps) => {
  let placeholder = props.placeholder || "Select a Date and Time";

  return (
    <>
    {props.control ? (
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        render={({ field, fieldState }) => (
          <>
            <Calendar 
            showTime
            hourFormat="12"
            value={field.value} 
            ref={field.ref}
            onChange={
              (e) => {
                // Update the controller value
                field.onChange(e.value);

                // Run our custom function
                if (props.onChange !== undefined) {
                  props.onChange(e);
                }
              }
            }
            placeholder={placeholder}
            tooltip={props.tooltip}
            tooltipOptions={props.tooltipOptions}
            disabled={props.disabled}
            minDate={props.minDate}
            maxDate={props.maxDate} />   

            {fieldState.invalid && // Error message
              <Input.ValidationError>{fieldState.error?.message}</Input.ValidationError>
            }
          </>
        )}
      />  
      ) : (
        <Calendar 
          name={props.name}
          value={props.value} 
          hourFormat="12"
          onChange={props.onChange} 
          placeholder={placeholder}
          tooltip={props.tooltip}
          tooltipOptions={props.tooltipOptions} 
          disabled={props.disabled}
          minDate={props.minDate}
          maxDate={props.maxDate}
          />     
        )}
    </>
  )
  
}

const Time = (props: TimeProps) => {
  let placeholder = props.placeholder || "Select a time";

  return (
    <>
    {props.control ? (
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules}
        render={({ field, fieldState }) => (
          <>
            <Calendar 
            timeOnly
            hourFormat="12"
            value={field.value} 
            ref={field.ref}
            onChange={
              (e) => {
                // Update the controller value
                field.onChange(e.value);

                // Run our custom function
                if (props.onChange !== undefined) {
                  props.onChange(e);
                }
              }
            }
            placeholder={placeholder}
            tooltip={props.tooltip}
            tooltipOptions={props.tooltipOptions}
            disabled={props.disabled} />   

            {fieldState.invalid && // Error message
              <Input.ValidationError>{fieldState.error?.message}</Input.ValidationError>
            }
          </>
        )}
      />  
      ) : (
      <Calendar 
        name={props.name}
        timeOnly
        value={props.value} 
        hourFormat="12"
        onChange={props.onChange} 
        placeholder={placeholder}
        tooltip={props.tooltip}
        tooltipOptions={props.tooltipOptions} 
        disabled={props.disabled}
        />     
      )}
    </>
  ) 
}


const ValidationError = (props : ValidationErrorProps) => {

  return (
    <p className='mb-0'><span style={{color: "red"}}>{props.children != null && props.children != "" ? props.children : "Value is required."}</span></p>
  )
};


const Editor = (props : EditorProps) => {  
  return (
    <>
      {props.control ? (
        <Controller 
          name={props.name}
          control={props.control} 
          rules={props.rules} 
          render={({ field, fieldState }) => (
            <>
              <PrimeReactEditor 
              id={field.name} 
              name={field.name} 
              value={field.value} 
              onTextChange={
                (e) => {
                  // Update the controller value
                  field.onChange(e.htmlValue);

                  // Run our custom function
                  if (props.onTextChange !== undefined) {
                    props.onTextChange(e);
                  }
                }
              } 
              style={props.style}
              disabled={props.disabled} />
              
              {fieldState.invalid && // Error message
                <Input.ValidationError>{fieldState.error?.message}</Input.ValidationError>
              }
            </>
          )}
        />
      ) : (
        <PrimeReactEditor 
          id={props.name} 
          name={props.name} 
          value={props.value} 
          disabled={props.disabled}
          onTextChange={
            (e) => {
              // Run our custom function
              if (props.onTextChange !== undefined) {
                props.onTextChange(e);
              }
            }
          }
          style={props.style} 
        />
      )}
    </>
    )
};

const RadioButtonList = (props : RadioButtonListProps) => {
  let defaultClassName = "flex flex-column gap-2";

  return (
    <>
      {props.control ? (
        <Controller 
          name={props.name}
          control={props.control}
          rules={props.rules}
          render={({ field, fieldState }) => (
            <>
              <div className={props.className ?? defaultClassName}>
                {props.options.map((option : RadioButtonOption, index: number) => {
                  return (
                    <div key={option.label} className="flex align-items-top">
                      <PrimeReactRadioButton 
                        inputId={field.name + "-" + index}
                        inputRef={field.ref} 
                        value={option.value} 
                        checked={field.value === option.value}
                        onChange={
                          (e) => {
                            // Update the controller value
                            field.onChange(e.value);
              
                            // Run our custom function
                            if (props.onChange !== undefined) {
                              props.onChange(e);
                            }
                          } 
                        } 
                        disabled={props.disabled}
                      />

                      <label htmlFor={field.name + "-" + index} className="ms-2">{option.label}</label>
                    </div>
                  );
                })}
              </div>
              {fieldState.invalid && // Error message
                <Input.ValidationError>{fieldState.error?.message}</Input.ValidationError>
              }
            </>
          )}
        />
      ) : (
        <div className={props.className ?? defaultClassName}>
          {props.options.map((option : RadioButtonOption, index: number) => {
            return (
              <div key={option.label} className="flex align-items-top">
                <PrimeReactRadioButton 
                inputId={props.name + "-" + index}
                value={option.value} 
                checked={props.value === option.value}
                onChange={props.onChange}
                disabled={props.disabled} />

                <label htmlFor={props.name} className="ms-2">{option.label}</label>
              </div>
            );
          })}
        </div>
      )}
    </>    
  )
}

const Checkbox = (props : CheckBoxProps) => {
  let checked = props.checked || false;

  return (
    <>
      {props.control ? (
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          render={({ field, fieldState }) => (
          <>
            <PrimeReactCheckbox 
            name={field.name}
            inputId={field.name} 
            checked={field.value} 
            inputRef={field.ref} 
            onChange={
              (e) => {
                // Update the controller value
                field.onChange(e.checked);

                // Run our custom function
                if (props.onChange !== undefined) {
                  props.onChange(e);
                }
              }
            } 
            disabled={props.disabled}/>

            <label htmlFor={field.name} className="ms-2">{props.label}</label>

            {fieldState.invalid && // Error message
              <Input.ValidationError>{fieldState.error?.message}</Input.ValidationError>
            }
          </> 
          )}
        />   
      ) : (
        <div className={'flex align-items-top ' + props.className}>
          <PrimeReactCheckbox 
            name={props.name}
            inputId={props.name} 
            checked={checked}
            onChange={props.onChange}
            disabled={props.disabled} 
          />

          <label htmlFor={props.name} className='ms-2'>{props.label}</label>
        </div>
      )}
    </>
  )
}

const Number = (props : NumberProps) => {
  let placeholder = props.placeholder || "";

  return (
    <>
      {props.control ? (
        <Controller 
        name={props.name} 
        control={props.control} 
        rules={props.rules}
        render={({ field, fieldState }) => (
          <>
            <InputNumber 
            mode={props.mode}
            locale="en-US"
            value={field.value} 
            id={field.name} 
            inputRef={field.ref} 
            minFractionDigits={props.minFractionDigits}
            onChange={
              (e) => {
               // Update the controller value
               field.onChange(e.value);
               // Run our custom function
               if (props.onChange !== undefined) {
                 props.onChange(e);
               }
              }
            } 

            className="width-100" 
            placeholder={placeholder} 
            tooltip={props.tooltip} 
            disabled={props.disabled}
            min={props.min}
            max={props.max} /> 

            {fieldState.invalid && // Error message
              <Input.ValidationError>{fieldState.error?.message}</Input.ValidationError>
            } 
          </>
        )}/>   
      ) : (      
        <InputNumber 
          name={props.name} 
          value={props.value} 
          mode={props.mode}
          id={props.name} 
          onChange={props.onChange}
          className="width-100" 
          placeholder={placeholder} 
          tooltip={props.tooltip} 
          locale="en-US"
          minFractionDigits={props.minFractionDigits}
          disabled={props.disabled}
          min={props.min}
          max={props.max}
        />
      )}
    </>
  )
}

const Currency = (props : CurrencyProps) => {
  let placeholder = props.placeholder || "";

  return (
    <>
      {props.control ? (
        <Controller 
        name={props.name} 
        control={props.control} 
        rules={props.rules}
        render={({ field, fieldState }) => (
          <>
            <InputNumber 
            mode="currency"
            currency="usd"
            inputRef={field.ref}
            locale="en-US"
            value={field.value} 
            id={field.name} 
            onChange={
              (e) => {
                // Update the controller value
                field.onChange(e.value);
                // Run our custom function
                if (props.onChange !== undefined) {
                  props.onChange(e);
                }
              }
            } 

            className="width-100" 
            placeholder={placeholder} 
            tooltip={props.tooltip} 
            disabled={props.disabled}
            min={props.min}
            max={props.max} /> 

            {fieldState.invalid && // Error message
              <Input.ValidationError>{fieldState.error?.message}</Input.ValidationError>
            } 
          </>
        )}/>   
      ) : (      
        <InputNumber 
          name={props.name} 
          value={props.value} 
          mode="currency"
          currency="usd"
          locale="en-US"
          id={props.name} 
          onChange={props.onChange}
          className="width-100" 
          placeholder={placeholder} 
          tooltip={props.tooltip} 
          disabled={props.disabled}
          min={props.min}
          max={props.max}
        />
      )}
    </>
  )
}

const AutoComplete = (props: AutoCompleteProps) => {
  let placeholder = props.placeholder || "";

  return (
    <InputAutoComplete
      field={props.field}
      value={props.value}
      suggestions={props.suggestions}
      onChange={props.onChange}
      onSelect={props.onSelect}
      onUnselect={props.onUnselect}
      completeMethod={props.completeMethod}
      placeholder={placeholder}
      multiple={props.multiple}
      disabled={props.disabled}
    />
  )
}

const Switch = (props: SwitchProps) => {
  let checked = props.checked || false;

  return (
    <>
      {props.control ? (
        <Controller
          name={props.name}
          control={props.control}
          rules={props.rules}
          render={({ field, fieldState }) => (
          <>
            <InputSwitch 
            name={field.name}
            inputId={field.name} 
            checked={field.value} 
            inputRef={field.ref} 
            onChange={
              (e) => {
                // Update the controller value
                field.onChange(e.value ?? false);

                // Run our custom function
                if (props.onChange !== undefined) {
                  props.onChange(e);
                }
              }
            } 
            disabled={props.disabled}/>
            <label htmlFor={field.name} className="d-inline">&nbsp;{props.label}</label>

            {fieldState.invalid && // Error message
              <Input.ValidationError>{fieldState.error?.message}</Input.ValidationError>
            }
          </> 
          )}
        />   
      ) : (
        <div className={'d-inline-block ' + props.className}>
          <InputSwitch 
            name={props.name}
            inputId={props.name} 
            checked={checked}
            onChange={props.onChange}
            disabled={props.disabled} 
          />
          <label htmlFor={props.name}>&nbsp;{props.label}</label>&nbsp;
        </div>
      )}
    </>
  )
}

const InputPassword = (props : PasswordProps) => {
  let placeholder = props.placeholder || "";

    return (
      <>
        {props.control ? (
          <Controller 
          name={props.name} 
          control={props.control} 
          rules={props.rules} 
          render={({ field, fieldState }) => (
            <>
              <InputPassword
              name={props.name} 
              value={field.value} 
              ref={field.ref} 
              onChange={
                (e) => {
                  let target = e.target;
                  target.value = target.value.replaceAll('~', '');

                  // Update the controller value
                  field.onChange(target.value);

                  // Run our custom function
                  if (props.onChange !== undefined) {
                    props.onChange(e);
                  }
                }
              } 
              onClick={props.onClick}
              icon={props.icon}
              maxLength={props.maxLength}
              className={"width-100 " + props.className}
              placeholder={props.placeholder} 
              tooltip={props.tooltip} 
              tooltipOptions={props.tooltipOptions} 
              disabled={props.disabled}
              onKeyUp={props.onKeyUp} 
              toggleMask={props.toggleMask}
              feedback={props.feedback} /> 

              {fieldState.invalid && // Error message
                <Input.ValidationError>{fieldState.error?.message}</Input.ValidationError>
              } 
            </>
          )}/>   
        ) : (
          <Password
            name={props.name} 
            value={props.value} 
            onChange={props.onChange}
            onClick={props.onClick}
            className={"width-100 " + props.className}
            placeholder={props.placeholder} 
            tooltip={props.tooltip} 
            tooltipOptions={props.tooltipOptions} 
            disabled={props.disabled}
            maxLength={props.maxLength}
            onKeyUp={props.onKeyUp}
            toggleMask={props.toggleMask}
            feedback={props.feedback}
          />
        )}
      </>
    )
  }

Input.Text = Text;
Input.Number = Number;
Input.Currency = Currency;
Input.AddressAutocomplete = AddressAutocomplete;
Input.SearchBar = SearchBar;
Input.TextArea = TextArea;
Input.Dropdown = SelectDropDown;
Input.FilterDates = FilterDates;
Input.FilterDropdown = FilterDropDown;
Input.Date = Date;
Input.DateTime = DateTime;
Input.Time = Time;
Input.Editor = Editor;
Input.ValidationError = ValidationError;
Input.RadioButtonList = RadioButtonList;
Input.Checkbox = Checkbox;
Input.MultiSelectDropDown = MultiSelectDropDown;
Input.AutoComplete = AutoComplete;
Input.Switch = Switch;
Input.Password = InputPassword;
  
export default Input;

