// File functions
export function formatFileSize(bytes : number)
{
    let type;
    let returnNumber;
    if (bytes >= 1048576) { // If greater than or equal to 1 MB
        // Convert to MB
        type = "MB";
        returnNumber = bytes / 1048576;
    }
    else {
        // Convert to KB
        type = "KB";
        returnNumber = bytes / 1024;
    }

    // Return the byte value with up to two decimal place accuracy
    return Math.round((returnNumber + Number.EPSILON) * 10) / 10 + " " + type;
}

// Utility Functions
export function consoleLog(message?: any) {
    const allowedEnvironments = ["http://localhost:3000", "https://dev.gob2g.com", "https://dev.mwdbe.com","https://qa.gob2g.com"];

    if (allowedEnvironments.includes(window.location.origin))
        console.log(message);
}

export function isEmptyObject(obj: object) {
    for(let prop in obj) {
        if(obj.hasOwnProperty(prop))
            return false;
    }
    return true;
}

export function formatCommaSeperatedNumber(number: number | string) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatCurrency(number: number) {
    return "$" + number.toFixed(2).toString().replace(/\d(?=(\d{3})+\.)/g, '$&,');

}

export function formatPhoneNumber(areaCode?: string, phoneNumber?: string, extension?: string) {
    let formattedPhoneNumber = "";

    if (areaCode) {
        formattedPhoneNumber += areaCode + "-";
    }

    if (phoneNumber && phoneNumber.length == 7) {
        formattedPhoneNumber += phoneNumber.substring(0, 3) + "-" + phoneNumber.substring(3, 7);
    }

    if (extension) {
        formattedPhoneNumber += " Ext. " + extension;
    }

    return formattedPhoneNumber;
}

function replaceAllInstancesOfTwoStringsAndEverythingInbetween(inputString: string, firstString : string, secondString : string, replaceValue: string) {

    while (inputString.indexOf(firstString)> -1) {
        inputString = replaceTwoStringsAndEverythingInbetween(inputString, firstString, secondString, replaceValue);
    }
    return inputString;
}

function replaceTwoStringsAndEverythingInbetween(inputString: string, firstString : string, secondString : string, replaceValue: string) {
    
    // Get the important values
    let firstIndex = inputString.indexOf(firstString);
    let inputStringAfterFirstString = inputString.substring(firstIndex, inputString.length);
    let secondIndex = inputStringAfterFirstString.indexOf(secondString) + firstIndex;

    // Get the text we want to replace
    let textToReplace = inputString.substring(firstIndex, secondIndex + secondString.length);

    // Replace the text
    return inputString.replace(textToReplace, replaceValue);
}

export function stripHTML(data : string) { // Copied from the VB6 misc utilities file

    // Remove duplicate spaces
    data = data.replace(/  +/g, ' ');

    // Remove header elements
    data = replaceAllInstancesOfTwoStringsAndEverythingInbetween(data, "<head", "</head>", "");

    // Remove script elements
    data = replaceAllInstancesOfTwoStringsAndEverythingInbetween(data, "<script", "</script>", "");

    // Remove style elements
    data = replaceAllInstancesOfTwoStringsAndEverythingInbetween(data, "<style", "</style>", "");

    // Replace all <td> and </td>
    data = replaceAllInstancesOfTwoStringsAndEverythingInbetween(data, "<td", ">", "");
    data = data.replaceAll("</td>", "");

    // Replace all <BR> and <LI> tags
    data = data.replaceAll("<br></br>", "");
    data = data.replaceAll("<br>", "");
    data = data.replaceAll("<li>", "- ");
    data = data.replaceAll("</li>", "");

    // Remove <P>, <DIV> and <TR> tags
    data = replaceAllInstancesOfTwoStringsAndEverythingInbetween(data, "<p", ">", "");
    data = data.replaceAll("</p>", "");
    data = replaceAllInstancesOfTwoStringsAndEverythingInbetween(data, "<div", ">", "");
    data = data.replaceAll("</div>", "");
    data = replaceAllInstancesOfTwoStringsAndEverythingInbetween(data, "<tr", ">", "");
    data = data.replaceAll("</tr>", "");

    // Remove links in the format JTS likes
    while (data.indexOf("href") > -1) {
        // Get the link from the html
        let startPos = data.indexOf("href=\"");
        startPos = startPos + 6;
        let dataAfterStartPos = data.substring(startPos, data.length);
        let endPos = dataAfterStartPos.indexOf("\"") + startPos;
        let link = data.substring(startPos, endPos);

        // Get the link text
        startPos = dataAfterStartPos.indexOf(">") + 1;
        endPos = dataAfterStartPos.indexOf("</a>");
        let linkText = dataAfterStartPos.substring(startPos, endPos);

        // Replace the link with his prefered format
        data = data.replace("href=\"", "");
        data = data.replace(">" + linkText + "<", ">" + linkText + " [" + link + "]" + "<");
    }

    // Remove all other tags
    data = replaceAllInstancesOfTwoStringsAndEverythingInbetween(data, "<", ">", "");

    // Replace a few other special characters
    data = data.replace("&nbsp;", " ");
    data = data.replace("&bull;", " * ");
    data = data.replace("&lsaquo;", "<");
    data = data.replace("&rsaquo;", ">");
    data = data.replace("&trade;", "(tm)");
    data = data.replace("&frasl;", "/");
    data = data.replace("&lt;", "<");
    data = data.replace("&gt;", ">");
    data = data.replace("&copy;", "(c)");
    data = data.replace("&reg;", "(r)");

    return data;
}