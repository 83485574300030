import { useRef } from 'react';

// Custom Components
import SectionBox from 'components/v1/main/structure/SectionBox';
import Footer from 'components/v1/main/structure/Footer';
import Button from 'components/v1/main/form/Button';
import DataTable from 'components/v1/main/structure/Table';

// Image imports
import B2GnowLogoImg from 'assets/v1/images/B2Gnow_RGB_FullLogo.png';
import SystemNewsSection from 'components/v1/main/dashboard/vendor/SystemNewsSection';
import ConcessionAuditsDashboardSection from 'components/v1/main/dashboard/vendor/ConcessionAuditsDashboardSection';
import ContractsDashboardSection from 'components/v1/main/dashboard/vendor/ContractsDashboardSection';
import ContractAuditsDashboardSection from 'components/v1/main/dashboard/vendor/ContractAuditsDashboardSection';
import ConcessionsDashboardSection from 'components/v1/main/dashboard/vendor/ConcessionsDashboardSection';

// Context variables


// Custom functions


const DashboardVENDOR = () => {

  // Reference variables
  const printRef = useRef<HTMLDivElement>(null);

  return (
    <>
      {/* The printable page section */}
      <div ref={printRef}>

        {/* Dashboard header */}
        <div className="header">
          <div className="row">
            <div className="col-xs-8 col-sm-10">
              <table id="dashboard-header" width="100%" cellSpacing="0" cellPadding="0">
                <tbody>
                  <tr>
                    <td align="left" valign="middle">
                      <h1>Business Name's</h1>
                      <img alt="B2Gnow Logo" src={B2GnowLogoImg}></img>
                      <h1>Dashboard</h1>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="container-fluid mb-0">
          <DashboardContent />
        </div>

        <Footer classes="" printRef={printRef} />
      </div>

    </>
  );
}

const DashboardContent = () => {
  return (
    <div className="row dashboard-content">
      <div className="col-md-6 col-sm-12">
        {/* Left Tiles */}
        <SectionBox title={"Dashboard"} color='green' className="mt-0">
          <ContractsDashboardSection />
          <ContractAuditsDashboardSection />
          <ConcessionsDashboardSection />
          <ConcessionAuditsDashboardSection />
          <CertificationsDashboardSection />
          <QuestionnairesDashboardSection />
          <UtilizationPlansDashboardSection />
        </SectionBox>
      </div>
      <div className="col-md-6 col-sm-12">
        {/* Right Tiles */}
        <SectionBox title={"Key Actions"} color='green' className="mt-0">
          <p className='mb-0'>This one's dynamic. We'll worry about it later.</p>
        </SectionBox>

        <SectionBox title={"Enhanced Account Security"} color='red' className="mt-0">
          <p className='mb-2'>Activate enhanced account security through multi-factor authentication.</p>
          <div className='mb-2'>
            <Button label="Activate Enhanced Account Security" onClick={() => window.location.href = "/Common/Utilities/MFAManagement.asp"} type="danger" outlined />
          </div>
          <p className='mb-0'>
            Enhanced account security: <a href="/Common/Utilities/MFAManagement.asp" style={{ color: "red" }}><strong>OFF</strong></a>
          </p>
        </SectionBox>

        <SectionBox title={"Configure"} color='green' className="mt-0">
          <div>
            <div style={{ float: "left", width: "65%" }}>
              <div style={{ marginBottom: "4px" }}><a href="/Common/Utilities/UserConfigChangePassword.asp">Change Your Password</a></div>

              <div style={{ marginBottom: "4px" }}><a href="/Common/Utilities/MFAManagement.asp" style={{ color: "red" }}><strong>Activate Enhanced Account Security</strong></a></div>

              <div style={{ marginBottom: "4px" }}><a href="/Vendor/Configuration/VendorConfigUserEdit.asp">Edit Your User Account Settings (Requires VendorID and UserID)</a></div>

              <div style={{ marginBottom: "10px" }}><a href="/Common/Utilities/WishList/WishListList.asp">View, Vote, &amp; Post to the Wish List</a></div>
            </div>
            <div style={{ float: "left", width: "34%" }}>
              <div style={{ marginBottom: "3px" }}><a href="/Vendor/Configuration/VendorConfigGeneralInfo.asp">Business Info (Requires vendorID)</a></div>

              <div style={{ marginBottom: "3px" }}><a href="/Vendor/Profile/VendorProfileSetup.asp">Profile Setup (Requires VendorID)</a></div>

              <div style={{ marginBottom: "3px" }}><a href="/Vendor/Console/ConsoleUsers.asp">List/Add Users (Requires vendorid)</a></div>

              <div style={{ marginBottom: "3px" }}><a href="/Vendor/Configuration/VendorConfigAccountPreferences.asp">Main Contacts (Requires vendorID)</a></div>

              <div style={{ marginBottom: "3px" }}><a href="/Vendor/Configuration/VendorConfigCommodityCodes.asp">Commodity Codes (Requires vendorID)</a></div>

              <div style={{ marginBottom: "3px" }}><a href="/Vendor/EEO/EEOList.asp">EEO/Workforce Comp. (Requires VendorID)</a></div>

            </div>
            <div style={{ clear: "both" }}></div>
          </div>
        </SectionBox>

        <SectionBox title={"System News"} color='green' className="mt-0">
          <SystemNewsSection webPageId={302} ></SystemNewsSection>
        </SectionBox>
      </div>
    </div>
  );
}

const CertificationsDashboardSection = () => {

  // normal variables
  const certifications = [{
    label: 'Status',
    active: -1,
    pending: -1,
    renewing: -1
  }];

  return (
    <DataTable.Single value={certifications}>
      <DataTable.Column
        field="label"
        header={<a href='/placeholder' className='text-decoration-none'><h6 className='mb-0 py-2'>Certifications</h6></a>}
        style={{ width: '40%' }}
      />
      <DataTable.Column field="active" header="Active" style={{ width: '20%' }} />
      <DataTable.Column field="pending" header="Pending" style={{ width: '20%' }} />
      <DataTable.Column field="renewing" header="Renewing" style={{ width: '20%' }} />
    </DataTable.Single>
  );
}

const QuestionnairesDashboardSection = () => {

  // normal variables
  const questionnaires = [{
    label: <strong className='text-red'>Denied</strong>,
    value: -1
  }, {
    label: 'Expired/Deactivated',
    value: -1
  }, {
    label: 'Withdrawn',
    value: -1
  }];

  return (
    <DataTable.Single value={questionnaires}>
      <DataTable.Column field="label" header={<a href='/placeholder' className='text-decoration-none'><h6 className='mb-0 py-2'>Questionnaires</h6></a>} />
      <DataTable.Column field="value" />
    </DataTable.Single>
  );
}

const UtilizationPlansDashboardSection = () => {

  // normal variables
  const utlizationPlans = [{
    label: <strong className='text-red'>Pending Submission</strong>,
    value: -1
  }, {
    label: 'Approved',
    value: -1
  }];

  return (
    <DataTable.Single value={utlizationPlans}>
      <DataTable.Column field="label" header={<a href='/placeholder' className='text-decoration-none'><h6 className='mb-0 py-2'>Utilization Plans</h6></a>} />
      <DataTable.Column field="value" />
    </DataTable.Single>
  );
}

export default DashboardVENDOR;