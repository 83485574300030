import { useContext, useEffect, useState } from "react"; import { APIHandlerContext } from 'GlobalContextProviders';
import { useParams } from "react-router-dom";
import { TermsOfServiceContent } from "types/v1/system/termsOfServiceContent";
import  'assets/v1/css/module/termsOfService/termsOfService.css'
const TosComponent = () => {
	const b2gAPI = useContext(APIHandlerContext);
	const { tosId } = useParams();
	const [termsOfServiceContent, setTermsOfServiceContent] = useState<TermsOfServiceContent | null>(null);


	useEffect(() => {
		let path = `/TOS/${tosId}`;
		b2gAPI.getTyped<TermsOfServiceContent>(
			path,
			(response) => {
				setTermsOfServiceContent(response);
			}
		)
	}, [b2gAPI])

	return (
		<>
			{termsOfServiceContent &&
				<div className="tos-display" contentEditable='false' dangerouslySetInnerHTML={{ __html: termsOfServiceContent.contents }} />
			}
		</>
	)
}


export default TosComponent;