import { MenuItem } from "primereact/menuitem";
import { isBuyerUserType, isDiversityUserType, isVendorUserType, UserInfo } from "types/v1/security/userAccessTypes";
import { consoleLog } from "../main/utilitiesFunctions";

// Tabs Functions
export function getActiveIndexBasedOnTabName(menuItems : MenuItem[], tabName : string) {
    for (let i = 0; i < menuItems.length; i++) {
        // Check if menu item label matches tab name
        if (menuItems[i].label?.toLowerCase() === tabName?.toLowerCase()) {
            return i;
        }
    }
    return -1;
}

// Common VB6 redirects
export function getVB6SiteOrigin() {
    //return process.env.REACT_APP_VB6_ORIGIN;
    return window.location.origin;
}

export function getVB6DashboardLink(userTypeID: number | undefined) {
    const origin = getVB6SiteOrigin();

    // We don't need to do anything if this undefine
    if (userTypeID !== undefined) {
        if (isVendorUserType(userTypeID)) {
            return origin + "/FrontPage/VendorFrontPage.asp";
        }
        else if (isBuyerUserType(userTypeID)) {
            return origin + "/FrontPage/BuyerFrontPage.asp";
        }
        else if (isDiversityUserType(userTypeID)) {
            return origin + "/FrontPage/StaffFrontPageV2.asp";
        }
    }

    return ""
}

export function getSendMessageLink(userID: number, TID: string, subject: string)
{
    const origin = getVB6SiteOrigin();

    subject = subject.replaceAll(" ", "+"); //replace spaces with + for url formatting
    TID = TID.replaceAll(" ", "+"); //replace spaces with + for url formatting

    return origin + "/Common/Messaging/Messaging/MessageComposeCreate.asp?" + `UID=${userID}&TID=${TID}&Subject=${subject}`;

}

export const buildCustomerSupportLink = (userAccess: UserInfo | undefined) => {
    // Zendesk code for when we're ready
    /*
    let requestType = "";
    if (isDiversityUserType(userAccess?.userTypeId)) {
        requestType = "Staff";
    }
    else if (isVendorUserType(userAccess?.userTypeId)) {
        requestType = "Vendor";
    }
    else {
        return "";
    }

    return `${getVB6SiteOrigin()}/SecureLogin/ZendeskSSO.aspx?UserID=${userAccess?.userId}&RequestType=${requestType}`;
    */
    return getVB6SiteOrigin() + "/Common/Messaging/Messaging/MessagingToB2Gnow.asp";
}
