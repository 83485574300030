import { Link, Outlet, useNavigate } from "react-router-dom";
import 'assets/v1/css/development/SidebarLayout.css'
import { LinkButton } from "components/v1/main/form/Button";
import { useContext, useEffect, useState } from "react";
import Input from "components/v1/main/form/Input";
import { DropdownChangeEvent } from "primereact/dropdown";
import axios from "axios";
import apiHandler, { getAPIURLFromOrigin } from "functions/v1/main/apiHandler";
import { isDiversityUserType, isVendorUserType } from "types/v1/security/userAccessTypes";
import { SidebarLayoutProps } from "types/v1/development/sidebarLayoutType";
import { consoleLog } from "functions/v1/main/utilitiesFunctions";
import { SetModuleConfigSettingsContext } from "GlobalContextProviders";

const SidebarLayout = (props : SidebarLayoutProps) => {

  // State variables
  const [userType, setUserType] = useState({ name: 'Staff', ID: 8 });
  const [disableUserTypeChange, setDisableUserTypeChange] = useState(true);
  const [manuallyEnteredBearerToken, setManuallyEnteredBearerToken] = useState<string | undefined>(undefined);
  const setModuleConfigSettings = useContext(SetModuleConfigSettingsContext);
 
  // Get Data (This is the one place we need use a normal axios post because we have to get the bearer token.)
  useEffect(() => {
    getAndSetBearerToken(userType.ID); // This sets the default bearer token based on the userType above
  }, []); // Only run once on creation of this component

  // Normal variables
  const userTypeOptions  = [
    { name: 'Vendor', ID: 1 },
    //{ name: 'Vendor', ID: 2 },
    //{ name: 'Staff', ID: 7 },
    { name: 'Staff', ID: 8 }
  ];

  // React Hooks
  const navigate = useNavigate();

  // Private functions
  const getAndSetBearerToken = (userTypeID: number) => {
    // Reset the closure variable with a new bearer token...
    if (isVendorUserType(userTypeID)) {
      // Set to vendor user
      //alert("Switching to vendor user!");
      setTimeout(function() { // Test delay
        axios.post(getAPIURLFromOrigin() + '/Authentication/getToken', 
        {
          "userName": "string",
          "passWord": "string",
          "sessionId": "Valid-SessionID"
        })
        .then(response => {
          // Setup a new closure variable in b2gAPI
          if (props.setB2gAPI !== undefined) {
            props.setAllowedRoutes({});
            props.setB2gAPI(apiHandler(props.defaultErrorHandler, response.data.value));
            setDisableUserTypeChange(false);
            if(setModuleConfigSettings != undefined)
              setModuleConfigSettings({});
          }
        })
        .catch(error => {       
          alert("We weren't able to get a valid bearer token.");
          console.error(error);
        });
      }, 1000); // Test 1 second delay
    }
    if (isDiversityUserType(userTypeID)) {
      // Set to staff user
      //alert("Switching to staff user!");
      setTimeout(function() { // Test delay
        axios.post(getAPIURLFromOrigin() + '/Authentication/getToken', 
        {
          "userName": "string",
          "passWord": "string",
          "sessionId": "Valid-SessionID-NotVendor"
        })
        .then(response => {
          // Setup a new closure variable in b2gAPI
          if (props.setB2gAPI !== undefined) {
            props.setAllowedRoutes({});
            props.setB2gAPI(apiHandler(props.defaultErrorHandler, response.data.value));
            setDisableUserTypeChange(false);
            if(setModuleConfigSettings != undefined)
              setModuleConfigSettings({});
          }
        })
        .catch(error => {       
          alert("We weren't able to get a valid bearer token.");
          console.error(error);
        });
      }, 1000); // Test 1 second delay
    }
  }

  //Event Handler
  const navigateOutreachCreate = () => {
    navigate("/outreach/create", { state: { outreachID: null } });
  }

  const changeUserType = (e: DropdownChangeEvent) => {
    setUserType(e.value);
    setDisableUserTypeChange(true);
    getAndSetBearerToken(e.value.ID);
  }

  return (
    <>
      <div id="sidebar">
        <h2>Dev Test Links</h2>
        <hr></hr>

        {(disableUserTypeChange) ? (
          <div className="text-center text-red">
            <strong>Bearer Token Not Set</strong>
          </div>
        ) : (
          <div className="text-center text-green">
            <strong>Bearer Token Set</strong>
          </div>
        )}

        <strong>UserType: </strong>
        <Input.Dropdown 
          name="UserType"
          value={userType}
          optionLabel="name" 
          options={userTypeOptions} 
          onChange={changeUserType}
          disabled={disableUserTypeChange}
        />
        <div className="text-center"><strong>or</strong></div>
        <Input.Text 
          name="BearerToken" 
          value={manuallyEnteredBearerToken}
          placeholder="Manually Enter Bearer Token" 
          tooltip="Updates on Edit" 
          onChange={(e) => {
            setManuallyEnteredBearerToken(e.target.value);
            setDisableUserTypeChange(true);
            setTimeout(function() { // Test delay
              if (props.setB2gAPI !== undefined) {
                props.setAllowedRoutes({});
                props.setB2gAPI(apiHandler(props.defaultErrorHandler, e.target.value));
              };
              setDisableUserTypeChange(false);
              if(setModuleConfigSettings != undefined)
                setModuleConfigSettings({});
            }, 1000); // Test 1 second delay
          }}
          onClick={(e) => {
            setManuallyEnteredBearerToken("");
          }}
        />

        <h5 className="mt-2">Development Pages</h5>
        <ol className="mb-2">
          <li>
            <Link to="/development">Component Library</Link>
          </li>
          <li>
            <Link to="/development/sandbox">Dev Sandbox</Link>
          </li>
        </ol>

        <h5>Outreach</h5>
        <ol className="mb-2">
          <li>
            <LinkButton label="Outreach Create" onClick={navigateOutreachCreate}/>
          </li>
          <li>
            <Link to="/outreach/campaign-list">Outreach Campaign List</Link>
          </li>
        </ol>

        <h5>Vendor Opportunity</h5>
        <ol className="mb-2">
          <li>
            <Link to="/vss/opportunity-list">Opportunity List</Link>
          </li>
          <li>
            <Link to="/vss/opportunity-view">Opportunity View</Link>
           </li>
           <li>
            <Link to="/vss/opportunity-reports">Opportunity Reports</Link>
           </li>
           <li>
            <Link to="/vss/outreach-view">Opportunity Outreach View</Link>
           </li>
           <li>
           <Link to="/vss/vendor-module-configuration">Vendor Module Configuration</Link>
           </li>
        </ol>

        <h5 className="mt-2">Contract Pages</h5>
        <ol className="mb-2">
          <li>
            <Link to="/contracts/linked-records">Linked Records</Link>
          </li>
        </ol>

        <h5 className="mt-2">Public Pages</h5>
        <ol className="mb-2">
          <li>
            <Link to="/outreach/public/list">Outreach Public List</Link>
          </li>
          <li>
          <Link to="/login/set-new-password">Set New Password Page(GUID required)</Link>
          </li>
          <li>
            <Link to="/login/set-new-password-error">Set New Password Error Page</Link>
          </li>
        </ol>
          </div>

      <div id="content">
        <Outlet />
      </div>
    </>
  )
};

export default SidebarLayout;