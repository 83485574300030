import { Navigate, Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import LoadingData from "../main/LoadingData";
const ComponentLibrary = lazy(() => import("./ComponentLibrary"));
const DevSandbox = lazy(() => import("./DevSandbox"));
const EditTemplate = lazy(() => import("./EditTemplate"));
const ViewTemplate = lazy(() => import("./ViewTemplate"));
const LazyLoadListTemplate = lazy(() => import("./LazyLoadListTemplate"));
const BlankEditTemplate = lazy(() => import("./BlankEditTemplate"));
const BlankViewTemplate = lazy(() => import("./BlankViewTemplate"));
const BlankListTemplate = lazy(() => import("./BlankListTemplate"));
const FileUploadTemplate = lazy(() => import("./FileUploadTemplate"));
const APICallTemplate = lazy(() => import("./ApiCallTemplate"));
const DetailedErrorPage = lazy(() => import("./DetailedError"));

const devRoutes = 
<>
  <Route index element={<Navigate to="/development" replace />} />
  <Route path="/development" element={<Suspense fallback={<LoadingData title="Loading Page Data"/>}><ComponentLibrary /></Suspense>} />
  <Route path="/development/sandbox" element={<Suspense fallback={<LoadingData title="Loading Page Data"/>}><DevSandbox /></Suspense>} />
  <Route path="/development/template/edit" element={<Suspense fallback={<LoadingData title="Loading Page Data"/>}><EditTemplate /></Suspense>} />
  <Route path="/development/template/view" element={<Suspense fallback={<LoadingData title="Loading Page Data"/>}><ViewTemplate /></Suspense>} />
  <Route path="/development/template/lazy-list" element={<Suspense fallback={<LoadingData title="Loading Page Data"/>}><LazyLoadListTemplate /></Suspense>} />
  <Route path="/development/template/blank-edit" element={<Suspense fallback={<LoadingData title="Loading Page Data"/>}><BlankEditTemplate /></Suspense>} />
  <Route path="/development/template/blank-view" element={<Suspense fallback={<LoadingData title="Loading Page Data"/>}><BlankViewTemplate /></Suspense>} />
  <Route path="/development/template/blank-list" element={<Suspense fallback={<LoadingData title="Loading Page Data"/>}><BlankListTemplate /></Suspense>} />
  <Route path="/development/template/file-upload" element={<Suspense fallback={<LoadingData title="Loading Page Data"/>}><FileUploadTemplate /></Suspense>} />
  <Route path="/development/template/api-call" element={<Suspense fallback={<LoadingData title="Loading Page Data"/>}><APICallTemplate /></Suspense>} />
  <Route path="/development/detailed-error" element={<Suspense fallback={<LoadingData title="Loading Page Data"/>}><DetailedErrorPage /></Suspense>} />
</>;

export default devRoutes;