import { SectionBoxRowProps, SectionBoxProps } from "types/v1/main/structure/sectionBoxTypes";

const SectionBox = (props : SectionBoxProps) => {

    let classes = "section-box";
    let requiredClasses = "section-box-top-content text-red text-bold";
    let titleClass = "title";
    
    if(props.required === true){
        classes += " mt-0";
    }

    if (props.className != null)
        classes += " " + props.className;

    if (props.color != null) {
        titleClass += "-" + props.color;
        classes += " " + props.color + " ";
    }

    if (props.requiredClassName != null)
        requiredClasses += " " + props.requiredClassName;

    return (
        <>
            {props.required &&
                <p className={requiredClasses}>
                    * required entry
                </p>
            }
            <div className={classes}>
                {props.title &&
                    <h2 className={titleClass }>{props.title}</h2>
                }
                {props.instructions && 
                    <div className="instructions">{props.instructions}</div>
                }
                {props.children}

            </div>
        </>
    )
  }


const Row = (props : SectionBoxRowProps) => {

    let classes = "row";
    let dataClasses = "col-xs-12 col-md-8";
    let labelClasses = "col-xs-12 col-md-4 field-label";

    if(props.data === true) {
        dataClasses += " field-data";
    }
    if(props.size === "large"){
        dataClasses += " text-lg";
    }
    if(props.required === true){
        labelClasses += " required-entry";
    }
    if(props.suggested === true){
        labelClasses += " suggested-entry";
    }
    if(props.className != undefined) {
        classes += " " + props.className;
    }
    
    return (
        <div className={classes}>
            <div className={labelClasses}>{props.label}</div>
            <div className={dataClasses}>{props.children}</div>
        </div>
    )
}

SectionBox.Row = Row;

export default SectionBox;

