import { format } from "date-fns";
import { Nullable } from "primereact/ts-helpers";
import { consoleLog } from "./utilitiesFunctions";

// Date functions
export function formatDateMMSlashDDSlashYY(date : Date)
{
    return date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear().toString().slice(-2);
}

export function formatDateMMDDYYYY(date : Nullable<Date>)
{
    let returnDate = "";

    if(date != null)
    {
        const dt = new Date(date);
        const dtDateOnly = new Date(dt.valueOf() + dt.getTimezoneOffset() * 60 * 1000);

        return returnDate = format(dtDateOnly, 'MM/dd/yyyy');
    }
    
    consoleLog('Invalid date for ' + date);
    return "";
}

export function isDateBeforeToday (date : Nullable<Date>) 
{
    let CurrentDate = new Date();

    if (date != null && (Date.parse(date?.toString()))/(24*3600*1000) - Date.parse(CurrentDate.toString())/(24*3600*1000) <= -1) 
        return true;
    else
        return false;
}
