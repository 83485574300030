import { useContext, useEffect, useState } from 'react';
import { APIHandlerContext } from 'GlobalContextProviders';
import { DashboardProp } from 'types/v1/main/dashboardTypes';
import RenderHtml from '../../structure/RenderHtml';

export default function SystemNewsSection(props: Readonly<DashboardProp>) {

  const b2gAPI = useContext(APIHandlerContext);
  const [news, setNews] = useState('');

  useEffect(() => {
    b2gAPI.get(`/system/webpages/${props.webPageId}`, function (response) {
      setNews(response.data.response[0].content);
    });
  }, [b2gAPI, props.webPageId]);

  return (<RenderHtml html={news} />);
};
