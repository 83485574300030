import { RenderHtmlProps } from 'types/v1/main/structure/renderHtmlTypes'; 
import DOMPurify from "isomorphic-dompurify";

const RenderHtml = (props : RenderHtmlProps) => {
    let purify = props.purify ?? true;

    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
      // DOMPurify sanitizes all link attributes;
      // this forces all links to open in new tabs after they're sanitized
      if (node.tagName === 'A') {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener');
      }
    });

    const setClassNames = () => {
      let classNames = props.className ?? "d-inline";
      
      if (props.ignoreExternalStyles === true) {
        classNames += " disable-external-styles";
      }

      return classNames;
    }

    return (
      <div 
        dangerouslySetInnerHTML={{ __html: purify ? DOMPurify.sanitize(props.html) : props.html }} 
        className={setClassNames()}
        style={props.style}
      ></div>
    )
  }

export default RenderHtml;

