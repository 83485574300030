import { Dialog } from "primereact/dialog";
import { ModalProps } from "types/v1/main/structure/modalTypes";

const Modal = (props : ModalProps) => {

    return (
        <Dialog 
            header={<span style={{color:"var(--blue-v1)"}}>{props.header}</span>} 
            visible={props.visible} 
            style={props.style ?? { width: '50vw' }}
            onShow={props.onShow}
            onHide={props.onHide}
            className={props.className}
            blockScroll={props.blockScroll}
			closable={props.closable??true}
        >
            {props.children}
        </Dialog>
    )
  }

export default Modal;

