import { DataTable as PrimeReactDataTable } from 'primereact/datatable';
import { DataTableColumnProps, DataTableSingleProps, DataTableMultipleProps } from 'types/v1/main/structure/tableTypes';

const DataTable = (props : any) => {  
    return (
       <></> 
    )
  }

const Single = (props : DataTableSingleProps) => {
    var size: "small" | "large" | "normal" | undefined = "small";
    //let size?: 'small' | 'normal' | 'large' | undefined;
    if(props.size !== undefined && props.size !== size){
        size = props.size;
    }

    return (
        <PrimeReactDataTable
            paginator={props.paginator}
            rowsPerPageOptions={props.rowsPerPageOptions}
            rows={props.rows}
            value={props.value} 
            tableStyle={props.style} 
            size={size}
            showGridlines={props.showGridlines} 
            stripedRows={props.stripedRows}
            rowHover={props.rowHover}
            paginatorTemplate={props.paginatorTemplate}
            currentPageReportTemplate={props.currentPageReportTemplate}
            paginatorLeft={props.paginatorLeft}
            paginatorRight={props.paginatorRight}
            filters={props.filters}
            filterDisplay={props.filterDisplay}
            header={props.header}
            globalFilterFields={props.globalFilterFields}
            lazy={props.lazy}
            loading={props.loading}
            first={props.first ?? 0}
            totalRecords={props.totalRecords}
            onPage={props.onPage}
            onSort={props.onSort}
            onFilter={props.onFilter}
            selection={props.selection}
            onSelectionChange={props.onSelectionChange}
            sortField={props.sortField}
            sortOrder={props.sortOrder}
            emptyMessage={props.emptyMessage ?? "No records found. Select different filter criteria."}
            rowGroupHeaderTemplate={props.rowGroupHeaderTemplate}
            rowGroupMode={props.rowGroupMode}
            expandableRowGroups={props.expandableRowGroups}
            expandedRows={props.expandedRows}
            groupRowsBy={props.groupRowsBy}
            onRowToggle={props.onRowToggle}
            rowExpansionTemplate={props.rowExpansionTemplate}
            className={props.className}
			selectionMode={props.selectionMode}
			dataKey={props.dataKey}
            onSelectAllChange={props.onSelectAllChange}
            selectAll={props.selectAll}
            showSelectAll={props.showSelectAll ?? true}
        >
            {props.children}
        </PrimeReactDataTable>
    )
  }

  const Multiple = (props : DataTableMultipleProps) => {
    var size: "small" | "large" | "normal" | undefined = "small";
    //let size?: 'small' | 'normal' | 'large' | undefined;
    if(props.size !== undefined && props.size !== size){
        size = props.size;
    }

    return (
        <PrimeReactDataTable 
            paginator={props.paginator}
            rowsPerPageOptions={props.rowsPerPageOptions}
            rows={props.rows}
            value={props.value} 
            tableStyle={props.style} 
            size={size}
            showGridlines={props.showGridlines} 
            stripedRows={props.stripedRows}
            rowHover={props.rowHover}
            paginatorTemplate={props.paginatorTemplate}
            currentPageReportTemplate={props.currentPageReportTemplate}
            paginatorLeft={props.paginatorLeft}
            paginatorRight={props.paginatorRight}
            filters={props.filters}
            filterDisplay={props.filterDisplay}
            header={props.header}
            globalFilterFields={props.globalFilterFields}
            lazy={props.lazy}
            loading={props.loading}
            first={props.first ?? 0}
            totalRecords={props.totalRecords}
            onPage={props.onPage}
            onSort={props.onSort}
            onFilter={props.onFilter}
            selection={props.selection}
            onSelectionChange={props.onSelectionChange}
            sortField={props.sortField}
            sortOrder={props.sortOrder}
            emptyMessage={props.emptyMessage ?? "No records found. Select different filter criteria."}
            rowGroupHeaderTemplate={props.rowGroupHeaderTemplate}
            rowGroupMode={props.rowGroupMode}
            expandableRowGroups={props.expandableRowGroups}
            expandedRows={props.expandedRows}
            groupRowsBy={props.groupRowsBy}
            onRowToggle={props.onRowToggle}
            rowExpansionTemplate={props.rowExpansionTemplate}
            className={props.className}
			selectionMode={props.selectionMode}
			dataKey={props.dataKey}
            onSelectAllChange={props.onSelectAllChange}
            selectAll={props.selectAll ?? true}
        >
            {props.children}
        </PrimeReactDataTable>
    )
  }


const Column = (props : DataTableColumnProps) =>{
    return(
        <Column 
            key={props.key} 
            field={props.field} 
            sortable={props.sortable}
            header={props.header} 
            style={props.style}
            filter={props.filter}
            filterPlaceHolder={props.filterPlaceHolder}
            showFilterMenu={props.showFilterMenu}
            body={props.body}
            filterElement={props.filterElement}
            selectionMode={props.selectionMode}
            headerStyle={props.headerStyle}
            bodyStyle={props.bodyStyle}
            expander={props.expander}
            hidden={props.hidden}
        ></Column>
    )
}

DataTable.Column = Column;
DataTable.Single = Single;
DataTable.Multiple = Multiple;

export default DataTable;

