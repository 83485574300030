import { lazy } from "react";
import { Route } from "react-router-dom";
const OpportunityList = lazy(() => import("./OpportunityList"));
const OpportunityView = lazy(() => import("./OpportunityView"));
const OpportunityVendorDetail = lazy(() => import("./OpportunityVendorDetail"));
const OpportunityVendorEngagement = lazy(() => import("./OpportunityVendorEngagement"));
const OpportunityReports = lazy(() => import("./OpportunityReports"));
const OpportunityVendorSearch = lazy(() => import("./OpportunityVendorSearch"));
const OpportunityOutreach = lazy(() => import("./OpportunityOutreach"));
const OpportunityOutreachView = lazy(() => import("./OpportunityOutreachView"));
const OpportunityVendorManagement = lazy(() => import("./OpportunityVendorManagement"));
const OpportunityOutreachStatistics = lazy(() => import("./OpportunityOutreachStatistics"));
const OpportunityOutreachTargets = lazy(() => import("./OpportunityOutreachTargets"));
const VendorModuleConfiguration = lazy(() => import("./VendorModuleConfiguration"));

const vssRoutes = 
<>
	<Route path="vss/opportunity-list" element={<OpportunityList />} />
	<Route path="vss/opportunity-view" element={<OpportunityView />} />
	<Route path="vss/opportunity-vendor-detail" element={<OpportunityVendorDetail />} />
	<Route path="vss/opportunity-vendor-engagement" element={<OpportunityVendorEngagement />} />
	<Route path="vss/opportunity-reports" element={<OpportunityReports />} />
	<Route path="/vss/opportunity-vendor-search" element={<OpportunityVendorSearch />} />
	<Route path="/vss/outreach-list" element={ <OpportunityOutreach/>}/>
	<Route path="/vss/outreach-view" element={ <OpportunityOutreachView/>}/>
	<Route path="/vss/opportunity-vendor-management" element={ <OpportunityVendorManagement/>}/>
	<Route path="/vss/outreach-statistics" element={ <OpportunityOutreachStatistics/>}/>
	<Route path="/vss/outreach-targets" element={ <OpportunityOutreachTargets/>}/>
	<Route path="/vss/vendor-module-configuration" element={<VendorModuleConfiguration />} />
</>;

/*
This is used to test that lazy loading works. It's commented out, but it can stay.
function delayForLazyDemo(promise: any) {
	return new Promise(resolve => {
	  setTimeout(resolve, 2000);
	}).then(() => promise);
  }
*/

export default vssRoutes;