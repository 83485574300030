import { Dispatch, SetStateAction, useLayoutEffect } from "react";
import { useOutletContext } from "react-router-dom";
import LoadingGif from "components/v1/main/structure/LoadingGif";
import { LoadingDataProps } from "types/v1/main/appTypes";

const LoadingData = (props: LoadingDataProps) => {

  // Set state variables
  const [setTitle] = useOutletContext<[Dispatch<SetStateAction<string>>]>(); // this comes from layout
 
  useLayoutEffect(() => {
    //Runs before the first render    
    setTitle(props.title ?? 'Loading Application Data');
  }, [setTitle]); // This is the dependency array. setTitle shouldn't change, but if it does, this function will rerun. I added this to remove a linter issue.
  
  return (
    <>
      {/*<p className="text-center mt-4">Loading data</p>*/}
      <LoadingGif loading center />
    </>
  );
}

export default LoadingData;