import { useState, useEffect, useContext } from "react";
import ReactToPrint from "react-to-print";

// Custom components
import { LinkButton } from "components/v1/main/form/Button";
import Modal from "./Modal";
import SectionBox from "./SectionBox";

// Image imports
import B2GnowIconImg from 'assets/v1/images/B2Gnow_RGB.png';

// Custom context provider
import { UserInfoContext } from "GlobalContextProviders";

// Custom functions
import { buildCustomerSupportLink, getVB6DashboardLink } from "functions/v1/navigation/utilitiesFunctions";

// Custom types
import { FooterProps } from "types/v1/main/structure/footerTypes";
import { isBuyerUserType, isDiversityUserType, isVendorUserType } from "types/v1/security/userAccessTypes";

// This is the printable component
const Footer = (props: FooterProps) => {

    // This is out Google translate function which is basically tantamount to the options we want for Google translate
    const googleTranslateElementInit = () => {
        //@ts-ignore
        new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
    }
    
    useEffect(() => { // This runs once on startup to setup our Google translate function in the current window from above
        //@ts-ignore
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, [])

    // State variables
    const [translateModalVisible, setTranslateModalVisible] = useState(false);

    const userAccess = useContext(UserInfoContext); // Get the user access stuff

    // Normal variables
    const origin = process.env.REACT_APP_VB6_ORIGIN;
    // home link prefix
    let homeLinkBase = getVB6DashboardLink(userAccess?.userTypeId);

    // random generated number between 1 and 9999 for XID value
    const min = 1;
    const max = 9999;
    const rand = Math.floor(Math.random() * (max - min + 1) + min);
    
    // get the correct user page ID based on the userTypeId.
    let UserPageTypeID = "";

        if(userAccess?.userTypeId !== undefined) {
            if (isVendorUserType(userAccess?.userTypeId)) {
                UserPageTypeID = "&VendorID=";
            }
            else if (isBuyerUserType(userAccess?.userTypeId)) {
                UserPageTypeID = "&BuyerID=";
            }
            else if (isDiversityUserType(userAccess?.userTypeId)) {
                UserPageTypeID = "&DiversityID=";
            }
        };

    // home link
    const homeLink = homeLinkBase + "?XID=" + rand + UserPageTypeID + userAccess?.companyId;

    const customerSupportLink = origin + "/Common/Messaging/Messaging/MessagingToB2Gnow.asp";
    const helpCenterLink = origin + "/SecureLogin/ZendeskSSO.aspx";
    let classes = "mt-4";
    if (props.classes != null) {
        classes = props.classes;
    }

    // Event handlers
    const openTranslateModal = () => {
        // Display the modal
        setTranslateModalVisible(true);
        // Add the script that sets up Google translate using the previously setup function
        var addScript = document.createElement('script');
        addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        try {
            //@ts-ignore
            document.getElementById("GoogleTranslateScript").innerHTML = "";
        } catch {}
        document.getElementById("GoogleTranslateScript")?.appendChild(addScript);
    }

    return (
        <>
            <Modal header="Translate" visible={translateModalVisible} style={{ width: '95vw' }} onHide={() => setTranslateModalVisible(false)}>
                <p className="m-0">
                    The translation options below are provided to assist users.  
                    They are <strong><span style={{color: "red"}}>experimental services</span></strong> by third parties that may provide varied results in terms of the quality and accuracy of the translation. 
                    It is the user's responsibility to ensure any instructions, content, labels, actions, and related information are understood before taking any action.<br/><br/>
                    Translations services may be discontinued or altered by the third parties without notice.
                </p>
                <SectionBox title="Available Translation Options">
                    <div id="google_translate_element"></div>
                </SectionBox>
            </Modal>

            <div id="footer" className={classes}>
                <table role="presentation" width="100%" cellSpacing="0" cellPadding="0" style={{borderTop: "1px solid #FFC700"}}>
                <tbody>
                    <tr>
                        <td valign="middle" align="left" className="smallgreentext">
                            <span className="smallgreentextBold">
                                {/*<LinkButton label="Customer Support" className="fw-bold" onClick={() => setCustomerSupportModalVisible(true)} />*/}
                                <a href={buildCustomerSupportLink(userAccess)} target="_blank" className="fw-bold">Customer Support</a>
                            </span>
                        </td>
                        <td align="right" valign="middle" className="smallgreentext">
                            <a href={homeLink}>Home</a>&nbsp;|&nbsp;
                            <ReactToPrint
                                trigger={() => <LinkButton label="Print to Printer/PDF" />}
                                content={() => props.printRef.current}
                            />
                            &nbsp;|&nbsp;
                            <LinkButton label="Translate" onClick={openTranslateModal} />
                            &nbsp;
                        </td>
                    </tr>
                    <tr>
                        <td align="left" valign="middle">
                            <span className="small-green-text">Copyright &copy; {new Date().getFullYear()} B2Gnow. All rights reserved.</span>
                        </td>
                        <td align="right" valign="middle">
                            <span className="small-green-text"><img alt="B2Gnow" src={B2GnowIconImg} style={{width: "1rem", verticalAlign: "middle"}}></img> Powered by <strong>B2Gnow</strong></span>
                        </td>
                    </tr>
                </tbody>
                </table>
            </div>
        </>
    );
}

export default Footer;