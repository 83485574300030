import { APIHandlerContext } from "GlobalContextProviders";
import { getVB6SiteOrigin } from "functions/v1/navigation/utilitiesFunctions";
import LoadingData from "pages/v1/main/LoadingData";
import { Dispatch, SetStateAction, Suspense, useContext, useEffect } from "react";
import { Navigate, Outlet, useLocation, useOutletContext } from "react-router-dom";
import { GeneralizedAuthLayoutProps } from "types/v1/security/PageSecurityTypes";

const GeneralizedAuthLayout = (props: GeneralizedAuthLayoutProps) => {

  // React hooks
  const location = useLocation();

  // Context variables
  const [setTitle] = useOutletContext<[Dispatch<SetStateAction<string>>]>(); // this comes from layout
  const b2gAPI = useContext(APIHandlerContext);

  // Match Function
  const isAllowed = () => {
    if (props.allowedRoutes) {
      // The replace Regex function removes '/' at the end of the pathname
      let pathname = location.pathname.replace(/\/+$/, '');
      // Check if the current url is in our array of allowed routes
      return props.allowedRoutes.some((x) => x === "/app" + pathname);
    }
    return false;
  }

  // Api Call
  useEffect(() => {
    if (props.allowedRoutes == undefined) {
      // If we haven't gotten the allowed routes for our module yet, we need to get them.
      b2gAPI.get(`/${props.moduleName}/accessControls`, function(response) {
        props.setAllowedRoutes(response.data.response.allowedRoutes);
      }, function (error) {
        if (error.response?.status === 401) {
          // If they have an invalid bearer token, log them out.
          window.location.href = getVB6SiteOrigin() + '/FrontPage/Logoff.asp';
        }
        else {
          // We don't give them any authorization if this call fails for some other reason
          props.setAllowedRoutes([]);
        }
      });
    }
  }, [b2gAPI]);

  // I think this is the easiest way to read this
  return (
    <>
      { props.allowedRoutes == undefined ? (
        <>
          {/* This is what we show while waiting for the page security api call */}
          <LoadingData />
        </>
      ) : (
        <>
          {isAllowed() ? (
            <>
              {/* Suspense is what we show while we wait for a lazy loaded page */}
              <Suspense fallback={<LoadingData title="Loading Page Data"/>}>
                {/* Outlet will end up being the route selected by the user */}
                <Outlet context={[setTitle]} />
              </Suspense>
            </>
          ) : (
            <Navigate to="/not-authorized" replace />
          )}
        </>
      )}
    </>
  );
};

export default GeneralizedAuthLayout;

