import { useContext, useEffect, useState } from 'react';
import { APIHandlerContext, UserInfoContext } from 'GlobalContextProviders';
import DataTable from 'components/v1/main/structure/Table';

export default function ConcessionAuditsDashboardSection() {

  const b2gAPI = useContext(APIHandlerContext);
  const userInfo = useContext(UserInfoContext);
  const [concessionAudits, setConcessionAudits] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (userInfo?.vendorId) {
      b2gAPI.get(`/concessions/audits/${userInfo.vendorId}/summary`, function (response) {
        const counts = response.data.response;
        let tableData = [];
        tableData.push({
          label: 'Total Audits',
          Last3Months: counts['auditCountTotal1'],
          Last6Months: counts['auditCountTotal2'],
          Last12Months: counts['auditCountTotal3']
        });
        tableData.push({
          label: <strong className='text-red'>Incomplete Audits</strong>,
          Last3Months: counts['auditCountIncomplete1'],
          Last6Months: counts['auditCountIncomplete2'],
          Last12Months: counts['auditCountIncomplete3']
        });
        tableData.push({
          label: <strong className='text-red'>Audit Discrepancies</strong>,
          Last3Months: counts['auditCountDiscrepancies1'],
          Last6Months: counts['auditCountDiscrepancies2'],
          Last12Months: counts['auditCountDiscrepancies3']
        });
        tableData = tableData.filter(x => x.Last3Months > 0 || x.Last6Months > 0 || x.Last12Months > 0);
        setConcessionAudits(tableData);
        setLoading(false);
      }, () => {
        setLoading(false);
      });
    }
  }, [b2gAPI, userInfo?.vendorId]);

  return (
    <DataTable.Single value={concessionAudits} loading={loading}>
      <DataTable.Column
        field="label"
        header={<a href='/placeholder' className='text-decoration-none'><h6 className='mb-0 py-2'>Concession Audits</h6></a>}
        style={{ width: '40%' }} />
      <DataTable.Column field="Last3Months" header="Last 3 months" style={{ width: '20%' }} />
      <DataTable.Column field="Last6Months" header="Last 6 months" style={{ width: '20%' }} />
      <DataTable.Column field="Last12Months" header="Last 12 months" style={{ width: '20%' }} />
    </DataTable.Single>
  );
};
