import { buildCustomerSupportLink, getVB6SiteOrigin } from "functions/v1/navigation/utilitiesFunctions";
import { UserInfoContext } from "GlobalContextProviders";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { isDiversityUserType, isVendorUserType } from "types/v1/security/userAccessTypes";

const HelpCenterLinks = () => {

    const origin = getVB6SiteOrigin();
    const userAccess = useContext(UserInfoContext); // Get the user access stuff
    const helpCenterLink = origin + "/SecureLogin/ZendeskSSO.aspx";
    const location = useLocation(); // Get the current url information

    const buildHelpCenterLink = (requestType: string) => {
        // Get module name
        let moduleName = "";
        if (location.pathname.startsWith("/vss/")) {
            moduleName = "fairsource";
        }
        else if (isDiversityUserType(userAccess?.userTypeId)) {
            moduleName = "Staff";
        }
        else if (isVendorUserType(userAccess?.userTypeId)) {
            moduleName = "Vendor";
        }
        else {
            return "";
        }

        // Build links
        let zendeskLink = `${helpCenterLink}?UserID=${userAccess?.userId}`;
        if(requestType == "faq") {
            zendeskLink += `&RequestType=${moduleName}FAQ`;
        } else if (requestType == "releaseNotes") {
            zendeskLink += `&RequestType=${moduleName}ReleaseNotes`;
        } else {
            zendeskLink += `&RequestType=${moduleName}`;
        }
        return zendeskLink;
    }

    const defaultSupportLinkOnly = () => {
        // Check the path for a module with special support links
        if (location.pathname.startsWith("/vss/")) {
            return false;
        }
        return true;
    }

    return(
        <>
        {(userAccess === undefined) ? (
            /* Show Loading when Needed */
            <ul>
                <li className="b2g_support_menu_title"><h4 className="margin_0">Loading Help Center...</h4></li>
            </ul>
        ) : (
            /* Display Links if Ready */
            <ul>
                <li className="b2g_support_menu_title"><h4 className="margin_0">Help Center</h4></li>
                { !defaultSupportLinkOnly() &&
                <>
                    <li>
                        <a href={buildHelpCenterLink("faq")} target="_blank">How-To Articles, Guides and FAQs</a>
                    </li>
                    <li>
                        <a href={buildHelpCenterLink("releaseNotes")} target="_blank">See What’s New</a>
                    </li>
                </>
                }
                <li>
                    <a href={buildCustomerSupportLink(userAccess)} target="_blank">Submit a Support Ticket</a>
                </li>
                <li>
                    <a href={buildHelpCenterLink("")} target="_blank"><strong className="text-red">NEW!</strong> Help Center</a>
                </li>
            </ul>
        )}
        </>
    )
}

export default HelpCenterLinks;

