import { UserInfoContext } from 'GlobalContextProviders';
import LoadingData from 'pages/v1/main/LoadingData';
import { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { UserTypeComponentSplitterProps } from 'types/v1/navigation/UserTypeComponentSplitterTypes';
import { isDiversityUserType, isVendorUserType } from 'types/v1/security/userAccessTypes';


const UserTypeComponentSplitter = (props : UserTypeComponentSplitterProps) => {

    const userAccess = useContext(UserInfoContext);

    // If we don't have the user type yet, send them to a loading page
    if (userAccess === undefined) {
        return <LoadingData title="Loading User Data"/>;
    }
    else if (isDiversityUserType(userAccess.userTypeId)) {
        return props.diversityComponent;
    }
    else if (isVendorUserType(userAccess.userTypeId)) {
        return props.vendorComponent;
    }
    else {
        return <Navigate to="/not-authorized" replace />;
    }
}

export default UserTypeComponentSplitter;

