import LoadingImg from 'assets/v1/images/busybox-bg.gif';
import LoadingSpinner from 'assets/v1/images/img_spinner_black.gif';
import { ProgressSpinner } from 'primereact/progressspinner';
import { LoadingGifProps, LoadingGifType } from 'types/v1/main/structure/loadingGifTypes';

const LoadingGif = (props: LoadingGifProps) => {

  let classes = "";
  if (props.center) {
    classes = "text-center"
  }
  if (props.className) {
    classes += " " + props.className;
  }

  return (
    <>
      {props.loading &&
        <div className={classes}>
          {props.type === LoadingGifType.DEFAULT || props.type == null ? (
            <ProgressSpinner style={props.style ?? { width: "10rem" }} />
          ) : (
            <img alt="Loading Gif" src={getGif(props.type)} style={props.style ?? { width: "10rem" }} />
          )}
        </div>
      }
    </>
  )
}

const getGif = (type?: LoadingGifType) => {
  switch (type) {
    case LoadingGifType.GEAR:
      return LoadingImg;
    case LoadingGifType.SPINNER:
      return LoadingSpinner;
    default:
      return LoadingImg;
  }
}


export default LoadingGif;

