import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter} from "react-router-dom";
import reportWebVitals from './reportWebVitals';

// Custom Components
import GlobalContextProviders from 'GlobalContextProviders';

// We define the setup of our app here
function App() {
  return (
    <BrowserRouter basename='/app'>
      {/* The routes are inside of RouteConfiguration which is inside of GlobalContextProviders */}
      <GlobalContextProviders />
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(consoleLog))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
