import { CardProps } from "types/v1/main/structure/CardTypes";

const Card = (props: CardProps) => {
    let classes = "p-card p-component p-3";
    if (props.className != null)
      classes += " " + props.className;

    return (
      <div className={classes}>
        {props.children}
      </div>
    )
  }

export default Card;

