import { Route } from "react-router-dom";
import { lazy, Suspense } from "react";
import LoadingGif from "components/v1/main/structure/LoadingGif";
const OutreachPublicList = lazy(() => import("./OutreachPublicList"));
const OutreachPublicLView = lazy(() => import("./OutreachPublicView"));


const outreachPublicRoutes = 
<>
    {/* Outreach Public Module Pages */}
	    <Route path="/outreach/public/List" element={<Suspense fallback={<LoadingGif loading center />}>
			<OutreachPublicList /></Suspense>
	}/>
	    <Route path="/outreach/public/View" element={<Suspense fallback={<LoadingGif loading center />}>
			<OutreachPublicLView /></Suspense>
	}/>
</>;

export default outreachPublicRoutes;