import { CSSProperties } from "react";

export interface LoadingGifProps {
    style?: CSSProperties
    center?: boolean
    loading: boolean,
    type?: LoadingGifType,
    className?: string
}

export enum LoadingGifType {
    DEFAULT,
    GEAR,
    SPINNER
}
