import { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import LoadingGif from "components/v1/main/structure/LoadingGif";
const SetNewPasswordAuthLayout = lazy(() => import("pages/v1/layout/security/SetNewPasswordAuthLayout"));
const SetNewPassword = lazy(() => import("./SetNewPassword"));
const SetNewPasswordError = lazy(() => import("./SetNewPasswordError"));

const SetNewPasswordRoutes =
	<>
		<Route element={<Suspense fallback={<LoadingGif loading center />}><SetNewPasswordAuthLayout/></Suspense>} >
			<Route path="/login/set-new-password" element={
				<SetNewPassword/>
			} />
		</Route>

		<Route path="/login/set-new-password-error" element={
			<Suspense fallback={<LoadingGif loading center />}><SetNewPasswordError /></Suspense>
		} />
	</>

export default SetNewPasswordRoutes;