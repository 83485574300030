import { Dispatch, SetStateAction, useLayoutEffect } from "react";
import { useOutletContext } from "react-router-dom";

const PageNotFound = () => {

  // Set state variables
  const [setTitle] = useOutletContext<[Dispatch<SetStateAction<string>>]>(); // this comes from layout
 
  useLayoutEffect(() => {
    //Runs before the first render    
    setTitle('Not Found');
  }, [setTitle]); // This is the dependency array. setTitle shouldn't change, but if it does, this function will rerun. I added this to remove a linter issue.
  
  return (
    <>
      <p className="text-center mt-4">Page Not Found</p>
    </>
  );
}

export default PageNotFound;