import { useContext, useEffect, useState } from 'react';
import { APIHandlerContext, UserInfoContext } from 'GlobalContextProviders';
import DataTable from 'components/v1/main/structure/Table';

export default function ContractAuditsDashboardSection() {

  const b2gAPI = useContext(APIHandlerContext);
  const userInfo = useContext(UserInfoContext);
  const [contractAudits, setContractAudits] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (userInfo?.vendorId) {
      b2gAPI.get(`/contracts/audits/${userInfo.vendorId}/summary`, function (response) {
        const counts = response.data.response;
        let tableData = [];
        tableData.push({
          label: 'Total Audits',
          total: counts['auditCountTotal1'] + counts['auditCountTotal2'],
          moreThan90Days: counts['auditCountTotal1'],
          lessThan90Days: counts['auditCountTotal2']
        });
        tableData.push({
          label: <strong className='text-red'>Incomplete Audits</strong>,
          total: counts['auditCountIncomplete1'] + counts['auditCountIncomplete2'],
          moreThan90Days: counts['auditCountIncomplete1'],
          lessThan90Days: counts['auditCountIncomplete2']
        });
        tableData.push({
          label: <strong className='text-red'>Past Due Audits</strong>,
          total: counts['auditCountPastDue1'] + counts['auditCountPastDue2'],
          moreThan90Days: counts['auditCountPastDue1'],
          lessThan90Days: counts['auditCountPastDue2']
        });
        tableData.push({
          label: <strong className='text-red'>Audit Discrepancies</strong>,
          total: counts['auditCountDiscrepancies1'] + counts['auditCountDiscrepancies2'],
          moreThan90Days: counts['auditCountDiscrepancies1'],
          lessThan90Days: counts['auditCountDiscrepancies2']
        });
        tableData = tableData.filter(x => x.total > 0);
        setContractAudits(tableData);
        setLoading(false);
      }, () => {
        setLoading(false);
      });
    }
  }, [b2gAPI, userInfo?.vendorId]);

  return (
    <DataTable.Single value={contractAudits} loading={loading}>
      <DataTable.Column
        field="label"
        header={<a href='/placeholder' className='text-decoration-none'><h6 className='mb-0 py-2'>Contract Audits</h6></a>}
        style={{ width: '40%' }} />
      <DataTable.Column field="total" header="Total" style={{ width: '20%' }} />
      <DataTable.Column field="moreThan90Days" header="< 90 days" style={{ width: '20%' }} />
      <DataTable.Column field="lessThan90Days" header="> 90 days" style={{ width: '20%' }} />
    </DataTable.Single>
  );
};
