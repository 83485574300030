import { LinkButton } from "components/v1/main/form/Button";
import { Dispatch, SetStateAction, useLayoutEffect } from "react";
import { useLocation, useOutletContext } from "react-router-dom";

const GenericErrorPage = () => {

  // Set state variables
  const [setTitle] = useOutletContext<[Dispatch<SetStateAction<string>>]>(); // this comes from layout
  useLayoutEffect(() => {
    //Runs before the first render    
    setTitle('Error');
  }, []); // This is the dependency array. setTitle shouldn't change, but if it does, this function will rerun. I added this to remove a linter issue.
  
  const goBack = () => {
    window.history.back();
  }

  // Set up error message and use it if it exists
  const location = useLocation();
  let errorMessage: null | string = null;
  if (location.state !== null) {
    errorMessage = location.state.errorMessage;
  }

  return (
    <>
      <p className="text-center mt-4">
        {/* Use the value from errorMessage if it isn't null */}
        {errorMessage ?? "We're Sorry, but we encountered an error."}
      </p>
      <p className="text-center mt-4"><LinkButton label="Click here" onClick={goBack} /> to go back to the previous page.</p>
    </>
  );
}

export default GenericErrorPage;