import { AxiosError, AxiosResponse } from "axios";

export interface ApiHandlerProps {
	getTyped: <T, >(path: string, successCallback?: (response: T) => void, failCallBack?: (error: AxiosError) => void) => void,
	postTyped: <T, V>(path: string, postData: T | undefined, successCallback?: (response: V) => void, failCallBack?: (error: AxiosError) => void) => AbortController | undefined,
	patchTyped: <T, V> (path: string, patchData: T | undefined, successCallback?: (response: V) => void, failCallBack?: (error: AxiosError) => void) => void,
	deletedTyped: <T, > (path: string, successCallback?: (response: T) => void, failCallBack?: (error: AxiosError) => void) => void,

	/**
	 * @deprecated Do not use this, use getTyped<T> instead
	 */
	get: (path: string, successCallback?: (response: AxiosResponse) => void, failCallBack?: (error: AxiosError) => void) => void,
	
	/**
	 * @deprecated Do not use this, use postTyped<T,V> instead
	*/
	post: (path: string, postData: object | undefined, successCallback?: (response: AxiosResponse) => void, failCallBack?: (error: AxiosError) => void) => AbortController | undefined,
	
	/**
	 * @deprecated Do not use this, use patchTyped<T,V> instead
	*/
	patch: (path: string, patchData: object | undefined, successCallback?: (response: AxiosResponse) => void, failCallBack?: (error: AxiosError) => void) => void,
	
	/**
	 * @deprecated Do not use this, use deleteTyped<T> instead
	*/
	deleteCall: (path: string, successCallback?: (response: AxiosResponse) => void, failCallBack?: (error: AxiosError) => void) => void,
	
	postFormData: (path: string, formData: FormData | undefined, successCallback?: (response: AxiosResponse) => void, failCallBack?: (error: AxiosError) => void) => void,	
	getFile: (path: string, fileName: string, failCallBack?: (error: AxiosError) => void) => void,
	all: (apiRequests: B2GApiRequest[], successCallback?: (responses: AxiosResponse[]) => void, failCallBack?: (error: AxiosError) => void) => void,
}

export enum B2GApiRequestType {
	Get
}

export interface B2GApiRequestTypedBase {
	path: string,
	data?: object,
	type: B2GApiRequestType,
	successCallback?: (responses: AxiosResponse) => void,
	failCallBack?: (error: AxiosError) => void
}

export interface B2GApiRequestTyped<T, V> {
	path: string,
	data?: T,
	type: B2GApiRequestType,
	successCallback?: (responses: V) => void,
	failCallBack?: (error: AxiosError) => void
}

export interface RequestConfiguration {
	signal: AbortSignal,
	withCredentials: boolean,
	headers: {}
}

export interface B2GApiRequest {
	path: string,
	data?: any,
	type: B2GApiRequestType
}