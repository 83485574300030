import { Routes, Route } from "react-router-dom";
import { useState } from "react";

// Custom types
import { RouteConfigurationProps } from "types/v1/main/appTypes";
import { AllowedRoutes } from "types/v1/security/PageSecurityTypes";

// Main Pages
import GenericErrorPage from 'pages/v1/main/Error';
import PageNotFound from 'pages/v1/main/PageNotFound';
import NotAuthorized from 'pages/v1/main/NotAuthorized';
import DashboardVENDOR from "pages/v1/main/DashboardVENDOR";

// Layout Pages
import Layout from 'pages/v1/layout/Layout';
import SidebarLayout from 'pages/v1/layout/SidebarLayout';
import GrayLayout from "pages/v1/layout/GrayLayout";
import GeneralizedAuthLayout from "pages/v1/layout/security/GeneralizedAuthLayout";

// Dev Pages
import devRoutes from "pages/v1/development/DevRoutes";

// Module Pages
import vssRoutes from "pages/v1/module/vss/VssRoutes";
import outreachRoutes from "pages/v1/module/outreach/OutreachRoutes";
import contractRoutes from "pages/v1/module/contracts/ContractRoutes";
import outreachPublicRoutes from "pages/v1/module/outreach/public/OutreachPublicRoutes";
import SetNewPasswordRoutes from "pages/v1/main/password/SetNewPasswordRoutes";
import TosComponeont from "components/v1/system/TosComponent";

// We define the routes for our application here in the App component
export default function RouteConfiguration (props: Readonly<RouteConfigurationProps>) {

  // State variables
  const [allowedRoutes, setAllowedRoutes] = useState<AllowedRoutes>({});

  // Base level application routes
  const applicationRoutes = 
  <>
    {/* Application Pages */}
    <Route path="/error" element={<GenericErrorPage />} />
    <Route path="/not-authorized" element={<NotAuthorized />} />
    <Route path="*" element={<PageNotFound />} />
	
  </>;

  // Module routes
  const authenticatedVssRoutes =
  <Route element={<GeneralizedAuthLayout moduleName="vendors" allowedRoutes={allowedRoutes.vss} setAllowedRoutes={(allowedRoutes: string[]) => {setAllowedRoutes({ ...allowedRoutes, vss: allowedRoutes })}}/>}>
    {vssRoutes}
  </Route>;

  const authenticatedOutreachRoutes = 
  <Route element={<GeneralizedAuthLayout moduleName="outreachcampaigns" allowedRoutes={allowedRoutes.outreach} setAllowedRoutes={(allowedRoutes: string[]) => {setAllowedRoutes({ ...allowedRoutes, outreach: allowedRoutes })}}/>}>
    {outreachRoutes}
  </Route>;

  const authenticatedContractRoutes =
  <Route element={<GeneralizedAuthLayout moduleName="contracts" allowedRoutes={allowedRoutes.contracts} setAllowedRoutes={(allowedRoutes: string[]) => {setAllowedRoutes({ ...allowedRoutes, contracts: allowedRoutes })}}/>}>
    {contractRoutes}
  </Route>;

  // Layout free routes
  const layoutFreeRoutes = 
  <>
    <Route path="/front-page" element={<DashboardVENDOR/>} />
	<Route path="/tos/:tosId" element={<TosComponeont/>} />
    {outreachPublicRoutes}
    {SetNewPasswordRoutes}
  </>

  // Functions
  const showDevPagesWhenDeployed = () => {
    switch(window.location.origin) {
      case "https://dev.gob2g.com":
      case "https://qa.gob2g.com":
      case "https://uat.gob2g.com":
          return true;
      default:
          return false;
    }
  }

  return (
    <>
      {(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? (
        /* Development (running on localhost) setup */
        <Routes>
          <Route element={<SidebarLayout setB2gAPI={props.setB2gAPI} defaultErrorHandler={props.defaultErrorHandler} setAllowedRoutes={setAllowedRoutes} />}>
            <Route element={<Layout />}>
              {/* Dev Routes */}
              {devRoutes}
              {/* Application Routes */}
              {applicationRoutes}
            </Route>
            <Route element={<GrayLayout />}>
              {authenticatedVssRoutes}
              {authenticatedOutreachRoutes}
              {authenticatedContractRoutes}
            </Route>
            {/* No layout page routes */}
            {layoutFreeRoutes}
          </Route>
        </Routes>
      ) : (
        /* Deployed setup */
        <Routes>
          <Route element={<Layout />}>
            {applicationRoutes}
            { showDevPagesWhenDeployed() && 
              devRoutes
            }
          </Route>
          <Route element={<GrayLayout />}>
            {authenticatedVssRoutes}
            {authenticatedOutreachRoutes}
          </Route>
          {layoutFreeRoutes}
        </Routes>
      )}
    </>
  );
}
