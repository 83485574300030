import { LinkButton } from "components/v1/main/form/Button";
import { Dispatch, SetStateAction, useLayoutEffect } from "react";
import { useOutletContext } from "react-router-dom";
import { NotAuthorizedProps } from "types/v1/main/appTypes";

const NotAuthorized = (props: NotAuthorizedProps) => {

  // Set state variables
  const [setTitle] = useOutletContext<[Dispatch<SetStateAction<string>>]>(); // this comes from layout
 
  useLayoutEffect(() => {
    //Runs before the first render    
    setTitle(props.title ?? 'Not Authorized');
  }, [setTitle]); // This is the dependency array. setTitle shouldn't change, but if it does, this function will rerun. I added this to remove a linter issue.
  
  const goBack = () => {
    window.history.back();
  }

  return (
    <>
      <p className="text-center mt-4">{props.message ?? "Not Authorized"}</p>
      <p className="text-center mt-4"><LinkButton label="Click here" onClick={goBack} /> to go back to the previous page.</p>
    </>
  );
}

export default NotAuthorized;