import { ReactNode, RefObject } from 'react';
import { Toast } from 'primereact/toast';
import { ConfirmDialog, confirmDialog as primeReactConfirmDialog } from 'primereact/confirmdialog';
import { ConfirmPopup, confirmPopup as primeReactConfirmPopup } from 'primereact/confirmpopup';
import { AlertInformation } from 'types/v1/main/alertTypes';

export function confirmDialog(header : string, message : string | ReactNode | undefined, accept : () => void, reject? : () => void) {
    
    primeReactConfirmDialog({
        message: message ?? 'Do you want to proceed?',
        header: header,
        icon: null,
        acceptClassName: 'p-button-warning',
        accept,
        reject,
        style: { width: '50vw' }
    });
}

export function confirmPopup(message: string | ReactNode | undefined, target : HTMLElement | undefined, accept : () => void, reject? : () => void) {
    primeReactConfirmPopup({
        target: target,
        message: message ?? 'Do you want to proceed?',
        icon: null,
        accept,
        reject,
        acceptClassName: 'pt-1 pb-1 ps-2 pe-2 ms-2',
        rejectClassName: 'p-button-info p-button-outlined ms-0 pt-1 pb-1 ps-2 pe-2'
    });
}

export function showAlertGlobal(alert : AlertInformation, toast : RefObject<Toast>) {
    const life = 15000;
    // summary set to null for now. b2gnow.primereact.css will need to be updated to show the div when we add it back in
    toast.current?.show({severity: alert.type, summary: null, detail: alert.detail, content: alert.content ,life: life});
}

const Alert = (props : any): any => {
    return (
        <>
            <Toast ref={props.innerRef} />
            <ConfirmDialog />
            <ConfirmPopup />
        </>
    );
}

export default Alert;