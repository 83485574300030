import { useContext, useEffect, useState } from 'react';
import { APIHandlerContext, UserInfoContext } from 'GlobalContextProviders';
import DataTable from 'components/v1/main/structure/Table';

export default function ContractsDashboardSection() {
  const b2gAPI = useContext(APIHandlerContext);
  const userInfo = useContext(UserInfoContext);
  const [contracts, setContracts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (userInfo?.vendorId) {
      b2gAPI.get(`/contracts/${userInfo.vendorId}/summary`, function (response) {
        const counts = response.data.response;
        let tableData = [];
        tableData.push({ label: 'Total', value: counts['contractTotalCount'] });
        tableData.push({ label: 'Open', value: counts['contractOpenCount'] });
        tableData.push({ label: 'Soon to end (3 mo)', value: counts['contractEndSoonCount'] });
        tableData.push({ label: 'Closed', value: counts['contractClosedCount'] });
        tableData = tableData.filter(x => x.value > 0);
        setContracts(tableData);
        setLoading(false);
      }, () => {
        setLoading(false);
      });
    }
  }, [b2gAPI, userInfo?.vendorId]);

  return (
    <DataTable.Single value={contracts} loading={loading}>
      <DataTable.Column field="label" header={<a href='/placeholder' className='text-decoration-none'><h6 className='mb-0 py-2'>Contracts</h6></a>} />
      <DataTable.Column field="value" />
    </DataTable.Single>
  );
};
