import { Button as PrimeReactButton } from 'primereact/button';
import { ButtonProps, LinkButtonProps } from 'types/v1/main/form/buttonTypes';

const Button = (props : ButtonProps) => { 

  let submit : "button" | "submit" = "button";
  if (props.submit === true) {
    submit = "submit";
  }

   let classes = "ms-1 me-1 ";
   if (props.removeMargin) {
    classes = "";
   }

   let type = props.type || "default";

   switch(type){
    case "success":
      classes += "p-button-success";
      break;
    case "warning":
      classes += "p-button-warning";
      break;
    case "danger":
    case "error":
      classes += "p-button-danger";
      break;
    case "default-blue":
      classes += "p-button-blue";
      break;
    case "default-v2":
      classes += "p-button-v2"
      break;
    case "dark-blue":
      classes += "p-button-dark-blue"
      break;
    case "info":
    case "default":
    case "primary":
    default: 
      classes += "p-button-info";
      break;
   }

   if (props.className != null) {
    classes += " " + props.className;
   }

    return (
      <PrimeReactButton 
        type={submit} 
        label={props.label} 
        icon={props.icon} 
        className={classes} 
        onClick={props.onClick} 
        outlined={props.outlined} 
        size={props.size} 
        text={props.text} 
        disabled={props.disabled} 
        style={props.style}
        loading={props.loading}
      >
      {props.children}
      </PrimeReactButton>
    )
  }

const SubmitButton = (props : ButtonProps) => {  

    return (
      <PrimeReactButton
        label={props.label}
        className={"ms-1 me-1 " + props.className}
        onClick={props.onClick}
        outlined={props.outlined}
        size={props.size}
        disabled={props.disabled}
        style={props.style}
        loading={props.loading}
      />
    )
  }

  const LinkButton = (props : LinkButtonProps) => {  

    let submit : "button" | "submit" = "button";
    if (props.submit === true) {
      submit = "submit";
    }

    // We need the font size to match how a normal link works I think
    // So, I made the font size 1em
    let style = {fontSize: '1em'};
    if (props.style != null) {
      style = {...props.style,
        fontSize: '1em'
      }
    }

    return (
      <PrimeReactButton 
      type={submit} 
      label={props.label} 
      className={"ms-1 me-1 link-button " + props.className} 
      onClick={props.onClick} 
      outlined={props.outlined} 
      size={props.size} 
      style={style}
      data-fieldname={props.dataFieldName} />
    )
  }

export default Button;
export { SubmitButton, LinkButton };

