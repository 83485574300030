import { useState, useRef, useEffect, useContext } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { useForm } from "react-hook-form";

// PrimeReact styling
//theme
import "primereact/resources/themes/bootstrap4-light-blue/theme.css";     
//core
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";    

// Prime react
import { Toast } from "primereact/toast";

// Custom components
import HelpCenterLinks from "components/v1/navigation/HelpCenterLinks";

// Custom styles
import 'assets/v1/css/global/b2gnow.css';
import 'assets/v1/css/global/b2gnow.header.css';
import 'assets/v1/css/global/b2gnow.modal.css';
import 'assets/v1/css/global/b2gnow.primereact.css';
import 'assets/v1/css/global/b2gnow.bootstrap.css';

// Custom Functions
import { LinkButton } from "components/v1/main/form/Button";
import { getVB6DashboardLink } from "functions/v1/navigation/utilitiesFunctions";
import { consoleLog } from "functions/v1/main/utilitiesFunctions";

// Custom context variables
import { ShowToastContext, UserInfoContext } from "GlobalContextProviders";

// Image imports
import Footer from "components/v1/main/structure/Footer";
import Card from "components/v1/main/structure/Card";

// This is the printable component
const GrayLayout = () => {

    // This is out Google translate function which is basically tantamount to the options we want for Google translate
    const googleTranslateElementInit = () => {
        //@ts-ignore
        new google.translate.TranslateElement({pageLanguage: 'en'}, 'google_translate_element');
    }
    
    useEffect(() => { // This runs once on startup to setup our Google translate function in the current window from above
        //@ts-ignore
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, [])

    // State variables
    const [title, setTitle] = useState('');

    const [customerSupportModalVisible, setCustomerSupportModalVisible] = useState(false);

    const printRef = useRef<HTMLDivElement>(null);

    const showAlert = useContext(ShowToastContext);

    const { register, handleSubmit, formState: { errors } } = useForm();

    const userAccess = useContext(UserInfoContext); // Get the user access stuff

    const navigate = useNavigate();

    //const [searchParams] = useSearchParams(); // Query string parameter hook

    // Normal variables
    const origin = process.env.REACT_APP_VB6_ORIGIN;
    let homeLink = getVB6DashboardLink(userAccess?.userTypeId);
    const trainingClassesLink = origin + "/Functions/Outreach/Common/EventList.asp";
    const trainingVideosLink = origin + "/Common/Utilities/Video/VideoList.asp";
    const customerSupportLink = origin + "/Common/Messaging/Messaging/MessagingToB2Gnow.asp";

    // Event handlers
    const navigateToOpportunityList = () => {
		navigate("/vss/opportunity-list");
	}

    const returnLinkVisible = () => {
        if (title == "Opportunity" || title == "Opportunity: Reports") {
            return true;
        } else {
            return false;
        }
    }
    

    const submitCustomerSupportMessage =  handleSubmit((data) => {
        // Convert the field data into a url search parameters object for the VB6 form
        var formData = new URLSearchParams();
        formData.append('Subject', data.Subject);
        formData.append('Message', data.Message);
        formData.append('Submit', 'Submit');
        //formData.append('TemplateName', 'Houston'); // Add later
        formData.append('PID', '302');

        // Send the data to the VB6 backend
        fetch(customerSupportLink, {
            method: 'post',
            body: formData,
        })
        .then((response) => response.text()) // Read the returned html data stream
        .then((text) => {
            consoleLog("Response html: " + text);

            if (text.includes("Please log back in.")) {
                // Handle the situation where we're not logged in to B2Gnow
                alert('You\'ve been logged out.  Please log back in.');
                //@ts-ignore And, we send them to the front page
                window.top.location = origin + "/FrontPage/LogOff.asp";
            }
            else if (text.includes("For reference, your ticket number is")) {
                // We've uploaded it successfully. So, we need to get the ticket number and display it.
                let ticketNumber = text.substring(
                    text.indexOf("For reference, your ticket number is <strong>") + 45, 
                    text.lastIndexOf("</strong>.")
                );
                // Show the success message
                showAlert({
                    type: 'success', 
                    summary: 'Success', 
                    detail: 'Your support request has been saved and queued for review. For reference, your ticket number is ' + ticketNumber + '.'
                });
                // Hide the customer support modal
                setCustomerSupportModalVisible(false)
            }
        })
        .catch(error => {
            // What to do on error
            alert("We've encountered a backend error.");
            consoleLog(error);
        })
    });

    return (
        <>
            {/* Modals */}
            {/*<Modal header="Send a Message to Customer Support" visible={customerSupportModalVisible} style={{ width: '95vw' }} onHide={() => setCustomerSupportModalVisible(false)}>
                <form onSubmit={submitCustomerSupportMessage}>
                    <p className="m-0">
                        Use this form to submit a customer support request.  Enter the details of your need and attach a file, if applicable.
                    </p>
                    <br></br>
                    <span style={{color: "red"}}>
                        <strong>
                            If you are reporting an issue and it is related to a particular record, or there are examples that can be reviewed by customer support, provide this information to reduce the time required to review and resolve the situation.  
                            The more detailed your submission, the faster the support team will be able to respond and resolve your concern.
                        </strong>
                    </span>
                    <SectionBox>
                        <SectionBox.Row label="Message Subject / Reference" required={true}>
                            <input {...register("Subject", { required: true })} className="p-inputtext p-component width-100" size={50} maxLength={200} />
                            {errors.Subject && 
                                <Input.ValidationError>Enter subject</Input.ValidationError>
                            }
                        </SectionBox.Row>
                        <SectionBox.Row label="Message" required={true}>
                            <textarea {...register("Message", {required: true})} cols={50} rows={15} className="p-inputtextarea p-inputtext p-component width-100" />
                            {errors.Message && 
                                <Input.ValidationError>Enter message</Input.ValidationError>
                            }
                        </SectionBox.Row>
                        <SectionBox.Row label="Attach File">
                            <FileUpload url={''} />
                        </SectionBox.Row>
                    </SectionBox>
                    <div className="d-flex flex-wrap gap-2 justify-content-center">
                        <Button label="Submit" type="submit" />
                        <Button label="Cancel" severity="secondary" onClick={() => setCustomerSupportModalVisible(false)} />
                    </div>
                </form>
            </Modal>
                        */}
            
            {/* The printable page section */}
            <div ref={printRef} className="b2g-background-gray min-vh-100">
                <div className="header b2g-background-gray">
                    <div className="row">
                        <div className="col-xs-8 col-sm-10">
                            <h1 className="title">{title}</h1>
                        </div>
                        <div className="col-xs-4 col-sm-2">                            
                            <div className="b2g_support_menu">
                                <div className="b2g_support_menu_wrapper">
                                    <ul>
                                        <li>
                                            <span className="fa fa-question-circle"></span>
                                            <HelpCenterLinks />
                                        </li>
                                        <li>
                                            <span className="fa fa-graduation-cap"></span>
                                            <ul>
                                                <li className="b2g_support_menu_title"><h4 className="margin_0">Training</h4></li>
                                                <li><a href={trainingClassesLink}>Training Classes</a></li>
                                                <li><a href={trainingVideosLink}>Training Videos</a></li>
                                            </ul>
                                        </li>
                                        <li>
                                            <span className="fa fa-print"></span>
                                            <ul>
                                                <li className="b2g_support_menu_title"><h4 className="margin_0">Print</h4></li>
                                                <li>
                                                    <ReactToPrint
                                                        trigger={() => <LinkButton label="Print to Printer/PDF" className="text-decoration-none text-left" />}
                                                        content={() => printRef.current}
                                                    />
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    {returnLinkVisible() &&
                        <div className="ps-5 mb-2 small-text">
                            <LinkButton onClick={navigateToOpportunityList} label="<< Return to Opportunity List"/>
                        </div>
                    }
                    
                </div>

                <div className="container-fluid">
                    <Card className='mb-3'>
                        <Outlet context={[setTitle]} />
                    </Card>
                </div>

                <Footer printRef={printRef} />
            </div>
        </>
    );
}

export default GrayLayout;