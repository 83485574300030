import { Route } from "react-router-dom";
import { lazy } from "react";
import UserTypeComponentSplitter from "components/v1/navigation/UserTypeComponentSplitter";
import FeatureAccessCheck from "components/v1/security/FeatureAccessCheck";
const OutreachManagement = lazy(() => import("./OutreachManagement"));
const OutreachContent = lazy(() => import("./OutreachContent"));
const OutreachContentEdit = lazy(() => import("./OutreachContentEdit"));
const OutreachCreate = lazy(() => import("./OutreachCreate"));
const OutreachSettings = lazy(() => import("./OutreachSettings"));
const OutreachStatistics = lazy(() => import("./OutreachStatistics"));
const OutreachVendorList = lazy(() => import("./OutreachVendorList"));
const OutreachListDiversity = lazy(() => import("./OutreachListDiversity"));
const OutreachListVendor = lazy(() => import("./OutreachListVendor"));
const OutreachViewResponse = lazy(() => import("./OutreachResponses"));
const OutreachView = lazy(() => import("./OutreachView"));
const OutreachLinkedRecords = lazy(() => import("./OutreachLinkedRecords"));

const outreachRoutes = 
<>
    {/* Outreach Module Pages */}
	<Route path="/outreach/campaign-list" element={<UserTypeComponentSplitter diversityComponent={<OutreachListDiversity />} vendorComponent={<OutreachListVendor />} />}/>
    <Route path="/outreach/view" element={
		<FeatureAccessCheck featureAccess="AccessOutreach">
			<OutreachView />
		</FeatureAccessCheck>
	}/>
	    <Route path="/outreach/management" element={
		<FeatureAccessCheck featureAccess="AccessOutreach">
			<OutreachManagement />
		</FeatureAccessCheck>
	}/>
	<Route path="/outreach/content-view" element={
		<FeatureAccessCheck featureAccess="AccessOutreach">
			<OutreachContent />
		</FeatureAccessCheck>
	}/>
	<Route path="/outreach/content-edit" element={
		<FeatureAccessCheck featureAccess="AccessOutreach">
			<OutreachContentEdit />
		</FeatureAccessCheck>
	}/>
	<Route path="/outreach/create" element={
		<FeatureAccessCheck featureAccess="CreateOutreachCampaign">
			<OutreachCreate />
		</FeatureAccessCheck>
	}/>
	<Route path="/outreach/settings" element={
		<FeatureAccessCheck featureAccess="AccessOutreach">
			<OutreachSettings />
		</FeatureAccessCheck>
	}/>
	<Route path="/outreach/statistics" element={
		<FeatureAccessCheck featureAccess="AccessOutreach">
			<OutreachStatistics />
		</FeatureAccessCheck>
	}/>
    <Route path="/outreach/vendor-list" element={
		<FeatureAccessCheck featureAccess="AccessOutreach">
			<OutreachVendorList />
		</FeatureAccessCheck>
	}/>
	<Route path="/outreach/response-view" element={
		<FeatureAccessCheck featureAccess="AccessOutreach">
			<OutreachViewResponse />
		</FeatureAccessCheck>
	}/>
	<Route path="/outreach/linked-records" element={
		<FeatureAccessCheck featureAccess="AccessOutreach">
			<OutreachLinkedRecords />
		</FeatureAccessCheck>
	}/>
</>;

export default outreachRoutes;