import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { consoleLog } from "./utilitiesFunctions";
import { ApiHandlerProps, B2GApiRequest, B2GApiRequestType, B2GApiRequestTyped, RequestConfiguration } from "types/v1/main/apiHandlerTypes";

export default function apiHandler(defaultErrorHandler: (error: AxiosError) => void, bearerToken = ""): ApiHandlerProps {

	const origin = getAPIURLFromOrigin();
	let token = bearerToken;
	let timeOut = 45000; //45 seconds
	let fileUploadTimeOut = 600000; // 10 minutes

	const createConfig = (): RequestConfiguration => {
		let config = {
			signal: AbortSignal.timeout(timeOut),
			withCredentials: true,
			headers: {}
		};

		// Only add the bearer token if we're running this locally
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			config.headers = {
				Authorization: "Bearer " + token
			}
		}
		return config;
	}

	const tokenCheck = (): boolean => {
		if (token == ""
			&& (!process.env.NODE_ENV || process.env.NODE_ENV === 'development')
		) {
			return true;
		}
		return false;
	}

	const defaultPipe = (input:any) => {
		return input.data.response;
	}

	// Typed ==========================================================
	const getTyped = <T,>(
			path: string, 
			successCallback?: (response: T) => void, 
			failCallBack?: (error: AxiosError) => void,
			pipeDefault: ((input:any) => any) = defaultPipe,
		) => {
		let config = createConfig();
		if (tokenCheck()) {
			return;
		}

		consoleLog(origin + path);
		axios.get(origin + path, config)
			.then(response => pipeDefault(response))
			.then(response => {
				if (successCallback !== undefined) {
					successCallback(response);
				}
				else {
					// add default success handler
				}
			})
			.catch(error => {
				if (failCallBack !== undefined) {
					failCallBack(error);
				}
				else {
					// add default fail handler
					if (error.code == "ERR_CANCELED") {
						error.message = "Request time out. Please try again or contact support."
					}
					defaultErrorHandler(error);
				}
			});
	};

	const postTyped = <T, V>(
			path: string, 
			postData: T | undefined,
			successCallback?: (response: V) => void,
			failCallBack?: (error: AxiosError) => void,
			pipeDefault: ((input:any) => any) = defaultPipe
		): AbortController | undefined => {
		const controller = new AbortController();
		let config = createConfig();
		if (tokenCheck()) {
			return;
		}

		axios.post(origin + path, postData, config)
			.then(response => pipeDefault(response))
			.then(response => {
				if (successCallback !== undefined) {
					successCallback(response);
				}
				else {
					// add default success handler
				}
			})
			.catch(error => {
				if (failCallBack !== undefined) {
					failCallBack(error);
				}
				else if (error.code == "ERR_CANCELED") {
					consoleLog(error);
				}
				else {
					defaultErrorHandler(error);
				}
			});

		return controller;
	}

	const patchTyped = <T, V>(
			path: string, 
			patchData: T | undefined, 
			successCallback?: (response: V) => void, 
			failCallBack?: (error: AxiosError) => void,
			pipeDefault: ((input:any) => any) = defaultPipe
		): void => {
		let config = createConfig();
		if (tokenCheck()) {
			return;
		}

		axios.patch(origin + path, patchData, config)
			.then(response => pipeDefault(response))
			.then(response => {
				if (successCallback !== undefined) {
					successCallback(response);
				}
				else {
					// add default success handler
				}
			})
			.catch(error => {
				if (failCallBack !== undefined) {
					failCallBack(error);
				}
				else {
					// add default fail handler
					if (error.code == "ERR_CANCELED") {
						error.message = "Request time out. Please try again or contact support."
					}
					defaultErrorHandler(error);
				}
			});
	}

	const deletedTyped = <T,>(
		path: string, 
		successCallback?: (response: T) => void, 
		failCallBack?: (error: AxiosError) => void,
		pipeDefault: ((input:any) => any) = defaultPipe
	): void => {
		let config = createConfig();
		if (tokenCheck()) {
			return;
		}

		axios.delete(origin + path, config)
			.then(response => pipeDefault(response))
			.then(response => {
				if (successCallback !== undefined) {
					successCallback(response);
				}
				else {
					// add default success handler
				}
			})
			.catch(error => {
				if (failCallBack !== undefined) {
					failCallBack(error);
				}
				else {
					// add default fail handler
					if (error.code == "ERR_CANCELED") {
						error.message = "Request time out. Please try again or contact support."
					}
					defaultErrorHandler(error);
				}
			});
	}
	// Not Typed ======================================================
	const get = (path: string, successCallback?: (response: AxiosResponse) => void, failCallBack?: (error: AxiosError) => void) => {
		let config = {
			signal: AbortSignal.timeout(timeOut),
			withCredentials: true,
			headers: {}
		};

		// Only add the bearer token if we're running this locally
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			config.headers = {
				Authorization: "Bearer " + token
			}
		}

		// If not development or if the bearer token exists, we want to run this call
		if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') || token !== "") {
			consoleLog(origin + path);
			axios.get(origin + path, config)
				.then(response => {
					if (successCallback !== undefined) {
						successCallback(response);
					}
					else {
						// add default success handler
					}
				})
				.catch(error => {
					if (failCallBack !== undefined) {
						failCallBack(error);
					}
					else {
						// add default fail handler
						if (error.code == "ERR_CANCELED") {
							error.message = "Request time out. Please try again or contact support."
						}
						defaultErrorHandler(error);
					}
				});
		}
	};

	const getFile = (path: string, fileName: string, failCallBack?: (error: AxiosError) => void) => {
		let config: AxiosRequestConfig = {
			signal: AbortSignal.timeout(timeOut),
			withCredentials: true,
			headers: {},
			responseType: 'blob'
		};

		// Only add the bearer token if we're running this locally
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			config.headers = {
				Authorization: "Bearer " + token
			}
		}

		// If not development or if the bearer token exists, we want to run this call
		if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') || token !== "") {
			axios.get(origin + path, config)
				.then(response => {
					// https://stackoverflow.com/questions/41938718/how-to-download-files-using-axios
					// create file link in browser's memory
					const href = URL.createObjectURL(response.data);

					// create "a" HTML element with href to file & click
					const link = document.createElement('a');
					link.href = href;
					link.setAttribute('download', fileName); //or any other extension
					document.body.appendChild(link);
					link.click();

					// clean up "a" element & remove ObjectURL
					document.body.removeChild(link);
					URL.revokeObjectURL(href);
				})
				.catch(error => {
					if (failCallBack !== undefined) {
						failCallBack(error);
					}
					else {
						// add default fail handler
						if (error.code == "ERR_CANCELED") {
							error.message = "Request time out. Please try again or contact support."
						}
						defaultErrorHandler(error);
					}
				});
		}
	};

	const setConfig = (formData: boolean = false) => {
		let config = {
			signal: AbortSignal.timeout(formData ? fileUploadTimeOut : timeOut),
			withCredentials: true,
			headers: {}
		};

		// Only add the bearer token if we're running this locally
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {

			if (formData) {
				config.headers = {
					'Authorization': "Bearer " + token,
					'Content-Type': 'multipart/form-data'
				}
			}
			else {
				config.headers = {
					'Authorization': "Bearer " + token
				}
			}
		}

		return config;
	}

	const postFormData = (path: string, formData: FormData | undefined, successCallback?: (response: AxiosResponse) => void, failCallBack?: (error: AxiosError) => void) => {
		let config = setConfig(true);


		// If not development or if the bearer token exists, we want to run this call
		if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') || token !== "") {
			axios.post(origin + path, formData, config)
				.then(response => {
					if (successCallback !== undefined) {
						successCallback(response);
					}
					else {
						// add default success handler
					}
				})
				.catch(error => {
					if (failCallBack !== undefined) {
						failCallBack(error);
					}
					else {
						// add default fail handler
						if (error.code == "ERR_CANCELED") {
							error.message = "Request time out. Please try again or contact support."
						}
						defaultErrorHandler(error);
					}
				});
		}
	}

	// The post functionality
	const post = (path: string, postData: object | undefined, successCallback?: (response: AxiosResponse) => void, failCallBack?: (error: AxiosError) => void) => {

		// Setup abort controller
		const controller = new AbortController();

		let config = {
			signal: controller.signal,
			withCredentials: true,
			headers: {}
		};

		// Only add the bearer token if we're running this locally
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			config.headers = {
				Authorization: "Bearer " + token
			}
		}

		// If not development or if the bearer token exists, we want to run this call
		if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') || token !== "") {
			axios.post(origin + path, postData, config)
				.then(response => {
					if (successCallback !== undefined) {
						successCallback(response);
					}
					else {
						// add default success handler
					}
				})
				.catch(error => {
					if (failCallBack !== undefined) {
						failCallBack(error);
					}
					else if (error.code == "ERR_CANCELED") {
						consoleLog(error);
					}
					else {
						defaultErrorHandler(error);
					}
				});

			return controller;
		}
	};

	// The patch functionality
	const patch = (path: string, patchData: object | undefined, successCallback?: (response: AxiosResponse) => void, failCallBack?: (error: AxiosError) => void) => {
		let config = {
			signal: AbortSignal.timeout(timeOut),
			withCredentials: true,
			headers: {}
		};

		// Only add the bearer token if we're running this locally
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			config.headers = {
				Authorization: "Bearer " + token
			}
		}

		// If not development or if the bearer token exists, we want to run this call
		if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') || token !== "") {
			axios.patch(origin + path, patchData, config)
				.then(response => {
					if (successCallback !== undefined) {
						successCallback(response);
					}
					else {
						// add default success handler
					}
				})
				.catch(error => {
					if (failCallBack !== undefined) {
						failCallBack(error);
					}
					else {
						// add default fail handler
						if (error.code == "ERR_CANCELED") {
							error.message = "Request time out. Please try again or contact support."
						}
						defaultErrorHandler(error);
					}
				});
		}
	};

	// The delete functionality
	const deleteCall = (path: string, successCallback?: (response: AxiosResponse) => void, failCallBack?: (error: AxiosError) => void) => {
		let config = {
			signal: AbortSignal.timeout(timeOut),
			withCredentials: true,
			headers: {}
		};

		// Only add the bearer token if we're running this locally
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			config.headers = {
				Authorization: "Bearer " + token
			}
		}

		// If not development or if the bearer token exists, we want to run this call
		if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') || token !== "") {
			axios.delete(origin + path, config)
				.then(response => {
					if (successCallback !== undefined) {
						successCallback(response);
					}
					else {
						// add default success handler
					}
				})
				.catch(error => {
					if (failCallBack !== undefined) {
						failCallBack(error);
					}
					else {
						// add default fail handler
						if (error.code == "ERR_CANCELED") {
							error.message = "Request time out. Please try again or contact support."
						}
						defaultErrorHandler(error);
					}
				});
		}
	};

	// The all functionality (This will make a set of api calls concurrently)
	const all = (apiRequests: B2GApiRequest[], successCallback?: (responses: AxiosResponse[]) => void, failCallBack?: (error: AxiosError) => void) => {
		let config = {
			signal: AbortSignal.timeout(timeOut),
			withCredentials: true,
			headers: {}
		};

		// Only add the bearer token if we're running this locally
		if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
			config.headers = {
				Authorization: "Bearer " + token
			}
		}

		// Build an array of requests
		let axiosApiCalls: any[] = [];
		apiRequests.forEach(element => {
			// Add each request into the array
			switch (element.type) {
				case B2GApiRequestType.Get:
					axiosApiCalls.push(
						axios.get(origin + element.path, config)
					);
					break;
				default:
				// Do nothing
			}
		});

		// If not development or if the bearer token exists, we want to run this call
		if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development') || token !== "") {
			axios.all(axiosApiCalls)
				.then(responses => {
					if (successCallback !== undefined) {
						successCallback(responses);
					}
					else {
						// add default success handler
					}
				})
				.catch(error => {
					if (failCallBack !== undefined) {
						failCallBack(error);
					}
					else {
						// add default fail handler
						if (error.code == "ERR_CANCELED") {
							error.message = "Request time out. Please try again or contact support."
						}
						defaultErrorHandler(error);
					}
				});
		}
	};

	// Return these functions so they can be used externally
	return {
		getTyped,
		postTyped,
		patchTyped,
		deletedTyped,
		get,
		getFile,
		post,
		patch,
		deleteCall,
		postFormData,
		all
	}
}

const currentDomainWithoutSubDomains = () => {
	// This only works based on the assumption that we have a .com ending (not .co.usa or something) for all urls
	let urlHost = window.location.host; // If (test.thing.com)
	let myArray = urlHost.split(".");
	return myArray[myArray.length - 2] + '.' + myArray[myArray.length - 1]; // Then (thing.com)
}

export function getAPIURLFromOrigin() {

	switch (window.location.origin) {
		case "http://localhost:3000":
			return "https://api-dev.gob2g.com/v1";
		case "https://dev.gob2g.com":
		case "https://dev.mwdbe.com":
			return `https://api-dev.${currentDomainWithoutSubDomains()}/v1`;
		case "https://qa.gob2g.com":
			return "https://api-qa.gob2g.com/v1";
		case "https://uat.gob2g.com":
			return "https://api-uat.gob2g.com/v1";
		case "https://stage.gob2g.com":
			return "https://api-stage.gob2g.com/v1";
		case "https://data.gob2g.com":
			return "https://api-data.gob2g.com/v1";
		case "https://demo.gob2g.com":
			return "https://api-demo.gob2g.com/v1";
		case "https://training.gob2g.com":
			return "https://api-training.gob2g.com/v1";
		case "https://test1.gob2g.com":
			return "https://api-test1.gob2g.com/v1";
		case "https://test2.gob2g.com":
			return "https://api-test2.gob2g.com/v1";
		case "https://data1.gob2g.com":
			return "https://api-data1.gob2g.com/v1";
		case "https://data2.gob2g.com":
			return "https://api-data2.gob2g.com/v1";
		default:
			return `https://api.${currentDomainWithoutSubDomains()}/v1`;
	}
}