import { ReactElement } from "react";

export interface ProtectedRouteProps {
  page?: ReactElement,
  vendorPage?: ReactElement,
  diversityPage?: ReactElement
}

export interface UserInfo {
  userTypeId: number,
  userId: number,
  firstName : string,
  lastName : string,
  email : string,
  companyId : number,
  vendorId : number,
  phone? : string,
  phoneArea?: string,
  phoneExt? : string,
  fax? : string,
  faxArea?: string,
  featureAccess?: FeatureAccess[]
}

export interface FeatureAccess {
  userFeatureID: number,
  name: string
}

// User Type Functions (based on Justin's VB6 app)
export function isVendorUserType(userTypeID : number | null | undefined) {
  if (userTypeID === 1 || userTypeID === 2) 
    return true;
  else
    return false;
}

export function isBuyerUserType(userTypeID : number | null | undefined) {
  if (userTypeID === 3 || userTypeID === 4) 
    return true;
  else
    return false;
}

export function isSuperUserType(userTypeID : number | null | undefined) {
  if (userTypeID === 5 || userTypeID === 6) 
    return true;
  else
    return false;
}

export function isDiversityUserType(userTypeID : number | null | undefined) {
  if (userTypeID === 7 || userTypeID === 8) 
    return true;
  else
    return false;
}

export function isMemberUserType(userTypeID : number | null | undefined) {
  if (userTypeID === 9 || userTypeID === 10) 
    return true;
  else
    return false;
}

export function isDiversityAdminType(userTypeID : number | null | undefined) {
  if (userTypeID === 7) 
    return true;
  else
    return false;
}
