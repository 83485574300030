import { UserInfoContext } from "GlobalContextProviders";
import LoadingData from "pages/v1/main/LoadingData";
import NotAuthorized from "pages/v1/main/NotAuthorized";
import { useContext } from "react";
import { FeatureAccessCheckProps } from "types/v1/security/PageSecurityTypes";
import { isDiversityUserType } from "types/v1/security/userAccessTypes";

const FeatureAccessCheck = (props: FeatureAccessCheckProps) => {

  // Context variables
  const userInfo = useContext(UserInfoContext);

  // Match Function
  const hasAccess = () => {
    if(isDiversityUserType(userInfo?.userTypeId))
      return userInfo?.featureAccess?.some((x) => x.name === props.featureAccess);
    else
      return true;
  }

  // I think this is the easiest way to read this
  return (
    <>
      { userInfo == undefined ? (
        <>
          {/* This is what we show while waiting for the user info api calls */}
          <LoadingData title="Checking Feature Access"/>
        </>
      ) : (
        <>
          {hasAccess() ? (
            props.children
          ) : (
            <NotAuthorized message="You do not have access to this feature. Please contact one of your Administrators to request access." />
          )}
        </>
      )}
    </>
  );
};

export default FeatureAccessCheck;

